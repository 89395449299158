import React, { useEffect, useState } from "react";
import axios from "axios";
import projectImage1 from "../../Image/Rectangle 34.png";
import noDataImage from "../../Image/App Illustrations.jpg";
import { Url } from "../../function/FunctionApi";
import "react-loading-skeleton/dist/skeleton.css";
import Swal from "sweetalert2";
import "./Projects.css";
import { officeNameMap } from "../../util/officeConstants";
import Loading from "./Loading";
import CompletedUi from "./ProjectsCards";
import StatusSelection from "./StatusSelection";
import types from "../../util/ProjectsType";
import axiosInstance from "../../api/apiClient";
const Projects = ({ userData }) => {
  const [completed, setCompleted] = useState();
  const [projects, setProjects] = useState([]);
  const [filteredProjects, setFilteredProjects] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");

  const [office, setOffice] = useState("");
  const filteredProjectsToDisplay = () => {
    let filtered = projects;

    if (completed === true) {
      filtered = filtered.filter((project) => !project.isArchived);
    } else if (completed === false) {
      filtered = filtered.filter((project) => project.isArchived);
    }

    return filtered;
  };
  useEffect(() => {
    setFilteredProjects(filteredProjectsToDisplay());
  }, []);

  useEffect(() => {
    setSearchTerm("");
    setFilteredProjects(filteredProjectsToDisplay());
  }, [completed]);
  useEffect(() => {
    const filterProjects = () => {
      console.log(office);
      let filtered = projects;

      filtered = completed
        ? filtered.filter((project) => !project.isArchived)
        : filtered.filter((project) => project.isArchived);

      if (office === "الجميع") {
        return filtered;
      }
      filtered = filtered.filter((project) => project.office.includes(office));

      return filtered;
    };

    setFilteredProjects(filterProjects());
  }, [office]);
  console.log(filteredProjects);
  const fetchProjects = async () => {
    try {
      setLoading(true);
      const apiUrl = "OrdersinHome/all-projects-inHome";
      const params = {
        branchName: userData?.branchName,
      };

      const response = await axios.get(`${Url}${apiUrl}`, { params });
      const projectData = [
        ...(response.data.data.RehabilitationWorks || []).map((project) => ({
          ...project,
          type: "أعمال التأهيل",
        })),
        ...(response.data.data.Constructions || []).map((project) => ({
          ...project,
          type: "الإنشاءات",
        })),
        ...(response.data.data.Emergencies || []).map((project) => ({
          ...project,
          type: "الطوارئ",
        })),
        ...(response.data.data.Maintenances || []).map((project) => ({
          ...project,
          type: "الصيانة",
        })),
      ];

      setProjects(projectData);
      setFilteredProjects(projectData);
    } catch (err) {
      setError(err.message || "An unexpected error occurred.");
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    console.log(userData?.branchName);

    if (userData && userData.branchName) {
      fetchProjects();
    }
  }, [userData]);

  useEffect(() => {
    if (!searchTerm.trim()) {
      setFilteredProjects(projects); // Reset to original data if search term is empty
      return;
    }
  
    const searchTermLower = searchTerm.toLowerCase();
  
    const results = projects.filter((project) => {
      const fieldsToSearch = [
        "workOrderType",
        "workDescription",
        "durationOfImplementation",
        "type",
        "orderType",
        "id",
        "orderCode",
        "faultNumber",
        "district",
        "contractor",
        "consultant",
        "appUserId",
        "userName",
        "branchName",
        "orderDate",
        "safetyViolationsExist",
        "note",
        "isArchived",
        "estimatedValue",
        "actualValue",
        "extractNumber",
        "createAt",
        "projectPlace",
        "office",
        "projectValue",
        "situation",
        "receiveDateTime",
      ];
  
      return fieldsToSearch.some((field) =>
        (project[field]?.toString().toLowerCase() ?? "").includes(searchTermLower)
      );
    });
  
    setFilteredProjects(results);
  }, [searchTerm, projects]);
  

  const handleDelete = async (projectId, projectType) => {
    console.log(projectType);
    const getProjectTypeRoute = (orderType) => {
      switch (orderType) {
        case types.construction:
          return "construction";
        case types.emergency:
          return "emergency";
        case types.maintenance:
          return "maintenance";
        default:
          return "rehabilitationworks";
      }
    };

    const projectTypeRoute = getProjectTypeRoute(projectType);

    const result = await Swal.fire({
      title: "هل أنت متأكد؟",
      text: "لا يمكنك التراجع عن هذا!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "نعم، احذف!",
      cancelButtonText: "لا، تراجع!",
    });

    if (result.isConfirmed) {
      try {
        const apiUrl = `Search/delete-by-orderidWithType?orderId=${projectId}&type=${projectTypeRoute}`;
        await axiosInstance.delete(`api/${apiUrl}`);

        Swal.fire({
          position: "center",
          icon: "success",
          title: "تم  تقديم طلب حذف سيتم المراجعه من خلال الادارة .",
          showConfirmButton: false,
          timer: 1500,
        });
        // fetchProjects();
        // window.location.reload();
      } catch (err) {
        Swal.fire({
          position: "center",
          icon: "error",
          title: "حدث خطأ أثناء حذف الطلب.",
          showConfirmButton: false,
          timer: 1500,
        });
      }
    }
  };

  if (loading) {
    return <Loading searchTerm={searchTerm} setSearchTerm={setSearchTerm} />;
  }

  if (error) return <div>Error: {error}</div>;
  console.log(filteredProjects);

  return (
    <div className="latest-projects-container min-h-screen projects-page" dir="rtl">
      {completed === true ? (
        <CompletedUi
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          filteredProjects={filteredProjects}
          setCompleted={setCompleted}
          noDataImage={noDataImage}
          office={office}
          setOffice={setOffice}
          projectImage1={projectImage1}
          handleDelete={handleDelete}
        />
      ) : completed === false ? (
        <CompletedUi
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          filteredProjects={filteredProjects}
          setCompleted={setCompleted}
          noDataImage={noDataImage}
          office={office}
          setOffice={setOffice}
          projectImage1={projectImage1}
          handleDelete={handleDelete}
        />
      ) : (
        <StatusSelection setCompleted={setCompleted} />
      )}
    </div>
  );
};

export default Projects;
