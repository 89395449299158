import React from "react";
import {
  ErrorModal,
  LoadingModal,
  SuccessModal,
} from "../../Component/Common/ModelComponents";
import RequestBtns from "../../Component/RequestBtns/RquestBtns";
import renderUploadSection from "../../Component/RenderFile/RenderFile";
import RadioGroup from "../../Component/RadioGroup/RadioGroup";
import SelectSituation from "../../Component/SelectSitution/SelectSitudation";
import SelectConsultant from "../../Component/SelectConsultant/SelectConusltant";
import WorkDescriptionInput from "../../Component/WorkDescriptionInput/WorkDescriptionInput";
import SelectDistrict from "../../Component/SelectDistrict/SelectDistrict";
import OfficeSelect from "../../Component/SelectOffice/SelectOffice";
import RequestStatus from "../../Component/RequestStatus/RequestStatus";
import SelectWorkOrderType from "../../Component/SelectWorkOrderType/SelectWorkOrderType";
import EquipmentTestTypeSelect from "../../Component/EquipmentTestTypeSelect/EquipmentTestTypeSelect";
import NumberOfEquipment from "../../Component/NumberOfEquipment/NumberOfEquipment";
import CableLengthInputs from "./CableLengthInputs";

function ConstructionFormInput({
  formData,
  handleChange,
  apiData,
  handleFileChange,
  fileInputRefs,
  openFileSelector,
  fileData,
  handleApiFileDelete,
  handleFileDelete,
  errorMessage,
  setShowModal,
  showModal,
  uploadProgress,
  handleSubmit,
  setLoading,
  refreshPage,
  loading,
  successMessage,
  isUpdate = false,
}) {
  return (
    <div className="form-container" dir="rtl">
      <div className="container">
        <div className="FormData">
          <div sx={{ textAlign: "center", marginBottom: "30px" }}>
            <h3>بيانات الطلب</h3>
            <p>Get a Quote Immediately Upon Form Submission</p>
          </div>

          <div className="flex flex-wrap flex-col lg:flex-row gap-3">
            <OfficeSelect
              selectedOffice={formData.Office}
              onOfficeChange={handleChange}
            />{" "}
            <div className="groub_fe">
              <label>نوع الانشاء</label>
              <select
                name="OrderType"
                value={formData.OrderType}
                onChange={handleChange}
                className="border w-full"
              >
                <option value="">اختر نوع الانشاء</option>
                <option value="مشاريع">مشاريع</option>
                <option value="توصيلات">توصيلات</option>
              </select>
            </div>
            <SelectWorkOrderType
              value={formData.WorkOrderType}
              handleChange={handleChange}
            />
          </div>

          <div className="flex flex-wrap flex-col lg:flex-row gap-3">
            <div className="groub_fe">
              <label>رقم امر العمل</label>
              <input
                type="text"
                name="FaultNumber"
                placeholder="رقم امر العمل"
                value={formData.FaultNumber || ""}
                onChange={handleChange}
                required
              />
            </div>
            {/* الحي */}
            <SelectDistrict
              officeName={formData.Office}
              value={formData.District}
              onChange={handleChange}
            />
          </div>

          <div className="flex flex-wrap flex-col lg:flex-row gap-3">
            <div className="groub_fe">
              <label>تاريخ الاسناد</label>
              <input
                type="date"
                name="CompletionDate"
                placeholder="تاريخ الاسناد"
                value={formData.CompletionDate || ""}
                onChange={handleChange}
                required
              />
            </div>
            {isUpdate && (
              <div className="groub_fe">
                <label>عدد ايام التاخير</label>
                <input
                  type="text"
                  name="NumberOfDaysDelayed"
                  placeholder="عدد ايام التاخير"
                  value={formData.NumberOfDaysDelayed || ""}
                  onChange={handleChange}
                  readOnly
                />
              </div>
            )}
            {isUpdate && (
              <div className="groub_fe">
                <label>عدد الايام المتبقيه</label>
                <input
                  type="text"
                  name="NumberOfDaysRemaining"
                  placeholder="NumberOfDaysRemaining"
                  value={formData.NumberOfDaysRemaining || ""}
                  onChange={handleChange}
                  readOnly
                />
              </div>
            )}
            {isUpdate && (
              <div className="groub_fe">
                <label>نسبه الانجاز</label>
                <input
                  type="text"
                  name="CompletionStatusReport"
                  placeholder=" نسبه الانجاز"
                  value={formData.CompletionStatusReport || ""}
                  onChange={handleChange}
                  readOnly
                />
              </div>
            )}
          </div>

          <RequestStatus
            situation={formData.Situation}
            handleChange={handleChange}
            implementationPhase={formData.ImplementationPhase}
          />

          <div className="flex flex-wrap flex-col lg:flex-row gap-3">
            <div className="groub_fe">
              <label> طول الحفريه للمشروع</label>
              <input
                type="text"
                name="ProjectExcavationLength"
                placeholder="طول الحفريه للمشروع"
                value={formData.ProjectExcavationLength || ""}
                onChange={handleChange}
                required
              />
            </div>
            {/* رقم المحطة */}
            <div className="groub_fe">
              <label>طول الحفريه المنفذه اليومي</label>
              <input
                type="text"
                name="DailyExcavationLength"
                placeholder="طول الحفريه المنفذه يوميا"
                value={formData.DailyExcavationLength || ""}
                onChange={handleChange}
                required
              />
            </div>

            {isUpdate && (
              <div className="groub_fe">
                <label>طول الحفريه المنفذه </label>
                <input
                  type="text"
                  name="ExcavationLength"
                  placeholder="طول الحفريه المنفذه"
                  value={formData.ExcavationLength || ""}
                  onChange={handleChange}
                  readOnly
                />
              </div>
            )}
          </div>

          <CableLengthInputs
            handleChange={handleChange}
            apiData={apiData}
            CableCompletion={formData.CableCompletion}
            CableLength={formData.CableLength}
            DailyCableLength={formData.DailyCableLength}
            ProjectCableLength={formData.ProjectCableLength}
          />
          <div className="flex flex-wrap flex-col lg:flex-row gap-3">
            {/* اسم المقاول */}
            <div className="groub_fe">
              <label>اسم المقاول</label>
              <input
                type="text"
                name="Contractor"
                placeholder="اسم المقاول"
                value={formData.Contractor || ""}
                onChange={handleChange}
                required
              />
            </div>

            <WorkDescriptionInput
              onChange={handleChange}
              value={formData.WorkDescription}
            />

            <EquipmentTestTypeSelect
              value={formData.TypeOfStomachTest}
              handleChange={handleChange}
            />
            {/* رقم المحطة */}
          </div>

          <div className="flex flex-wrap flex-col lg:flex-row gap-3">
            <NumberOfEquipment
              value={formData.NumberOfEquipment}
              handleChange={handleChange}
            />
            {/* مدة التنفيذ */}
            <div className="groub_fe">
              <label>مدة التنفيذ</label>
              <input
                type="text"
                name="DurationOfImplementation"
                placeholder="مدة التنفيذ"
                value={formData.DurationOfImplementation || ""}
                onChange={handleChange}
                required
              />
            </div>

            {/* الاستشاري */}
            <SelectConsultant
              value={formData.Consultant}
              onChange={handleChange}
            />
          </div>

          <div className="flex flex-wrap flex-col lg:flex-row gap-3">
            {/* تاريخ التنفيذ */}

            <div className="groub_fe">
              <label>رقم المحطه</label>
              <input
                type="text"
                name="StationNumber"
                placeholder="رقم المحطه"
                value={formData.StationNumber || ""}
                onChange={handleChange}
                required
              />
            </div>
            <div className="groub_fe">
              <label>تاريخ التنفيذ</label>
              <input
                type="date"
                name="OrderDate"
                placeholder="تاريخ التنفيذ"
                value={formData.OrderDate || ""}
                onChange={handleChange}
                required
              />
            </div>
            {/* القيمة التقديرية */}
            <div className="groub_fe">
              <label>القيمة التقديرية</label>
              <input
                type="text"
                name="EstimatedValue"
                placeholder="القيمة التقديرية"
                value={formData.EstimatedValue || ""}
                onChange={handleChange}
                required
              />
            </div>
          </div>

          <div className="input-group">
            {/* القيمة الفعلية */}
            <div className="groub_fe">
              <label>القيمة الفعلية</label>
              <input
                type="text"
                name="ActualValue"
                placeholder="القيمة الفعلية"
                value={formData.ActualValue || ""}
                onChange={handleChange}
                required
              />
            </div>
            {/* رقم المستخلص */}
            <div className="groub_fe">
              <label>رقم المستخلص</label>
              <input
                type="text"
                name="ExtractNumber"
                placeholder="رقم المستخلص"
                value={formData.ExtractNumber || ""}
                onChange={handleChange}
                required
              />
            </div>
          </div>

          <div className="input-group">
            <textarea
              name="Note"
              placeholder="الملاحظات"
              value={formData.Note}
              onChange={handleChange}
            />
          </div>

          <div className="input-group"></div>
          <RadioGroup
            label="مخالفات السلامة"
            options={[
              { label: "يوجد", value: "exists" },
              { label: "لا يوجد", value: "not-exists" },
            ]}
            name="SafetyViolationsExist"
            selectedValue={formData.SafetyViolationsExist}
            onChange={handleChange}
          />

          {formData.SafetyViolationsExist &&
            renderUploadSection(
              "صور مخالفات السلامة",
              "SafetyWastePhotos",
              "صور المخالفات",
              handleFileChange,
              fileInputRefs,
              openFileSelector,
              fileData,
              handleApiFileDelete,
              handleFileDelete
            )}
          <div className="input-group">
            <textarea
              name="DescriptionViolation"
              placeholder="وصف المخالفه"
              value={formData.DescriptionViolation}
              onChange={handleChange}
            />
          </div>
          {renderUploadSection(
            "المستندات ",
            "ModelPhotos",
            "تصوير مستندات مع الختم",
            handleFileChange,
            fileInputRefs,
            openFileSelector,
            fileData,
            handleApiFileDelete,
            handleFileDelete
          )}
          {renderUploadSection(
            "صور الموقع",
            "SitePhotos",
            "صور الموقع",
            handleFileChange,
            fileInputRefs,
            openFileSelector,
            fileData,
            handleApiFileDelete,
            handleFileDelete
          )}

          {renderUploadSection(
            "مستندات الاختبار",
            "TestModels",
            "مستندات الاختبار",
            handleFileChange,
            fileInputRefs,
            openFileSelector,
            fileData,
            handleApiFileDelete,
            handleFileDelete
          )}

          <RequestBtns
            loading={loading}
            Situation={formData.Situation}
            handleSubmit={handleSubmit}
          />
        </div>
      </div>
      {/* Progress Modal */}
      <LoadingModal
        show={loading}
        onHide={() => setLoading(false)}
        uploadProgress={uploadProgress}
      />
      <SuccessModal
        show={showModal.success}
        onHide={refreshPage}
        message={successMessage}
      />
      <ErrorModal
        show={showModal.error}
        onHide={() => setShowModal({ error: false, success: false })}
        message={errorMessage}
      />
    </div>
  );
}

export default ConstructionFormInput;
