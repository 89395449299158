import React, { useEffect, useState } from "react";
import ProfileForm from "./ProfileForm";
import VacationRequest from "./VacationRequest";
import { useMutation, useQuery } from "@tanstack/react-query";
import axiosInstance from "../../api/apiClient";
import Swal from "sweetalert2";
import { LoadingModal } from "../../Component/Common/ModelComponents";

const EngProfile = () => {
  const [profile, setProfile] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);

  // Fetch Profile
  const { data, isLoading, error } = useQuery({
    queryKey: ["profileData"],
    queryFn: async () => {
      const response = await axiosInstance.get(
        "api/Account/get-engineer-profile"
      );
      return response.data.data;
    },
    onSuccess: (data) => {
      setProfile(data);
    },

  });

  useEffect(() => {
    if (data) {
      setProfile(data);
    }
  }, [data]);

  // Handle profile update
  const { mutate: updateProfile, isLoading: isUpdating } = useMutation({
    mutationFn: async (updatedProfile) => {
      const formData = new FormData();

      // Append file if selected
      if (selectedFile) {
        formData.append("files1", selectedFile);
      }

      // Append other profile data
      Object.entries(updatedProfile).forEach(([key, value]) => {
        if (value !== null && value !== undefined) {
          formData.append(key, value);
        }
      });

      const response = await axiosInstance.put(
        "api/Account/update-engineer-profile",
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );

      return response.data;
    },
    onSuccess: () => {
      Swal.fire({
        title: "تم التحديث!",
        text: "تم تحديث الملف الشخصي بنجاح!",
        icon: "success",
        confirmButtonText: "موافق",
      });
    },
    onError: () => {
      Swal.fire({
        title: "خطأ!",
        text: "حدث خطأ أثناء تحديث الملف الشخصي، يرجى المحاولة مرة أخرى.",
        icon: "error",
        confirmButtonText: "إغلاق",
      });
    },
  });

  // Handle form input change
  const handleChange = (e) => {
    const { name, value, type, files } = e.target;

    if (type === "file") {
      setSelectedFile(files[0]); // Store selected file separately
    } else {
      setProfile((prevProfile) => ({
        ...prevProfile,
        [name]: value,
      }));
    }
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!profile) return;

    const formattedProfile = {
      ...profile,
      hireDate: profile.hireDate
        ? new Date(profile.hireDate).toISOString()
        : null,
      dateOfBirth: profile.dateOfBirth
        ? new Date(profile.dateOfBirth).toISOString()
        : null,
      residenceExpiryDate: profile.residenceExpiryDate
        ? new Date(profile.residenceExpiryDate).toISOString()
        : null,
    };

    updateProfile(formattedProfile);
  };

  // Handle Loading & Error
  if (isLoading)
    return (
      <div className="w-full h-screen items-center justify-center">
      ....loading
      </div>
    );
  if (error)
    return <p className="text-red-500">حدث خطأ أثناء تحميل البيانات!</p>;

  return (
    <div
      className="flex justify-center items-center  mt-40 lg:mt-20"
      style={{ backgroundColor: "rgba(42, 56, 91, 1)" }}
    >
      <div
        className="lg:w-[90vw] w-full bg-white p-6 rounded-lg shadow-lg border border-gray-200"
        dir="rtl"
      >
        <h2
          className="text-2xl pt-12 font-semibold text-center mb-4 text-secondary"
          style={{ color: "rgba(188, 145, 92, 1)" }}
        >
          الملف الشخصي
        </h2>
        {profile && (
          <ProfileForm
            profile={profile}
            handleChange={handleChange}
            handleSubmit={handleSubmit}
            isUpdating={isUpdating}
          />
        )}
        <VacationRequest />
      </div>
    </div>
  );
};

export default EngProfile;
