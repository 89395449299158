import { React, useEffect, useState } from "react";
import { postDatatoQueryParams } from "../../function/FunctionApi";
import { useNavigate } from "react-router-dom";
import logo from "../../Image/logo.png";
import logo2 from "../../Image/image-removebg-preview (1) 1.png";
import backgroundImage from "../../Image/loginside.jpeg";
import "./Login.css";

export function setCookie(name, value, hours) {
  const expirationDate = new Date();
  expirationDate.setTime(expirationDate.getTime() + hours * 60 * 60 * 1000);
  const cookieString = `${encodeURIComponent(name)}=${encodeURIComponent(
    value
  )};expires=${expirationDate.toUTCString()};path=/`;
  document.cookie = cookieString;
}

export function getCookie(name) {
  const cookies = document.cookie.split(";");
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim();
    if (cookie.startsWith(name + "=")) {
      return decodeURIComponent(cookie.substring(name.length + 1));
    }
  }
  return null;
}

function isCookieValid(name) {
  return getCookie(name) !== null;
}

function Login({ setUserData }) {
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const [data, setData] = useState({
    UserName: "",
    Password: "",
  });

  useEffect(() => {
    if (isCookieValid("user")) {
      navigate("/");
    }
  }, [navigate]);

  const handleChange = (e) => {
    const { id, value } = e.target;
    setData((prevData) => ({ ...prevData, [id]: value }));
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError("");

    try {
      const location = await new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const { latitude, longitude } = position.coords;
            resolve({ latitude, longitude });
          },
          (error) => {
            reject(error);
          }
        );
      });

      localStorage.setItem("userLocation", JSON.stringify(location));

      const result = await postDatatoQueryParams("Account/login", data);

      if (result) {
        setCookie("user", JSON.stringify(result.data), 12);
        setUserData(result.data);

        const userType = result.data.userType;
        localStorage.setItem("userType", userType);
        console.log("user-data", result.data);

        localStorage.setItem("IsRiyadh", result.data.branchName !== "جدة");
        localStorage.setItem(
          "isAllowed",
          result.data.userCanCreateProjectOutsideCityType
        );
        localStorage.setItem("branchId", result.data.branchId);

        if (userType === "eng") {
          navigate("/main-page");
        } else if (userType === "admin" || userType === "supervisor") {
          navigate("/home-page");
        } else {
          navigate("/");
        }
      }
    } catch (error) {
      if (error.code === error.PERMISSION_DENIED) {
        // User refused to share location
        setError("يجب الموافقة على مشاركة الموقع لتسجيل الدخول.");
      } else {
        // Other errors (e.g., login failure)
        const errorMessage =
          error.response?.data?.message || "البيانات غير صحيحة، حاول مرة أخرى";
        setError(errorMessage);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="Login" dir="rtl">
      <div className="image-container">
        <img
          src={backgroundImage}
          alt="Background"
          className="background-image"
        />
        <div className="cover">
          <img src={logo} alt="Logo" className="logo !w-[250px]" />
        </div>
      </div>

      {/* Form section */}
      <div className="form-container">
        <img src={logo} alt="Logo" className="logo-left" />
        <h2>أهلا بكم في عصف</h2>
        <form onSubmit={handleFormSubmit} dir="rtl">
          <label htmlFor="UserName">اسم المستخدم</label>
          <input
            type="text"
            id="UserName"
            value={data.UserName}
            onChange={handleChange}
            disabled={loading}
          />

          <label htmlFor="Password">كلمة المرور</label>
          <input
            type="password"
            id="Password"
            value={data.Password}
            onChange={handleChange}
            disabled={loading}
          />

          <button type="submit" className="!bg-mainColor" disabled={loading}>
            {loading ? "جاري تسجيل الدخول" : "تسجيل دخول"}
          </button>

          {error && <p className="error-message">{error}</p>}
        </form>
      </div>
    </div>
  );
}

export default Login;
