import React from "react";
import { motion } from "framer-motion";
import "./Sidebar.css";
import { Link, useLocation } from "react-router-dom";

function Item({ icon, name, id, link, handleLogout, isSubMenu }) {
  const subheading = {
    true: {
      opacity: 1,
    },
    false: {
      opacity: 0,
      display: "none",
    },
  };
  const location = useLocation();
  const isActive = location.pathname === link;

  return (
    <Link
      to={link}
      className={`${isActive ? "active" : "item"}`}
      id={id}
      onClick={handleLogout}
      transition={{
        type: "none",
        duration: 0.1,
      }}
    >
      <motion.div className="icon">
        <i
          className={isSubMenu && isActive ? "fa-regular fa-circle-dot" : icon}
        ></i>
      </motion.div>
      <motion.span variants={subheading}>{name}</motion.span>
    </Link>
  );
}

export default Item;
