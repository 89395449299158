import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import { domain, Url } from "../../function/FunctionApi";
import Swal from "sweetalert2";
import { useParams, useSearchParams } from "react-router-dom";
import SelectConsultant from "../../Component/SelectConsultant/SelectConusltant";
import SelectDistrict from "../../Component/SelectDistrict/SelectDistrict";
import {
  ErrorModal,
  LoadingModal,
  SuccessModal,
} from "../../Component/Common/ModelComponents";
import SelectSituation from "../../Component/SelectSitution/SelectSitudation";
import WorkDescriptionInput from "../../Component/WorkDescriptionInput/WorkDescriptionInput";
import RequestBtns from "../../Component/RequestBtns/RquestBtns";
import renderUploadSection from "../../Component/RenderFile/RenderFile";
import RadioGroup from "../../Component/RadioGroup/RadioGroup";
import endpointMap from "../../util/ModelsProjectsUrls";
import SelectOffice from "../../Component/SelectOffice/SelectOffice";
import SelectWorkOrderType from "../../Component/SelectWorkOrderType/SelectWorkOrderType";
import OfficeSelect from "../../Component/SelectOffice/SelectOffice";

const Form = ({ userData, apiData }) => {
  const [searchParams] = useSearchParams();
  const { id } = useParams();

  const createUrl = `${Url}RehabilitationWorks/create-rehabilitationWorks
`;
  const updateUrl = `${Url}RehabilitationWorks/update/${id}`;

  const [formData, setFormData] = useState({
    WorkOrderType: "",
    FaultNumber: "",
    FaultType: "",
    District: "",
    Office: "",
    OrderDate: "",
    WorkDescription: "",
    StationNumber: "",
    DurationOfImplementation: "",
    QualificationClassification: "",
    ReceiveDateTime: "",
    Situation: "",
    Contractor: "",
    Consultant: "",
    Note: "",
    SafetyViolationsExist: false,
    EstimatedValue: "",
    ActualValue: "",
    ExtractNumber: "",
  });

  const [fileData, setFileData] = useState({
    ModelPhotos: [],
    SitePhotos: [],
    SafetyWastePhotos: [],
  });

  const fileInputRefs = {
    ModelPhotos: useRef(null),
    SitePhotos: useRef(null),
    SafetyWastePhotos: useRef(null),
  };

  const [errorMessage, setErrorMessage] = useState("");
  const token = userData?.token;
  const [showModal, setShowModal] = useState({ success: false, error: false });
  const [loading, setLoading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [successMessage, setSuccessMessage] = useState("");

  useEffect(() => {
    if (apiData) {
      console.log(apiData);
      setFormData({
        WorkOrderType: apiData.workOrderType || "",
        Office: apiData.office || "",
        ReceiveDateTime: apiData.receiveDateTime.split("T")[0] || "",
        FaultType: apiData.faultType || "",
        FaultNumber: apiData.faultNumber || "",
        Contractor: apiData.contractor || "",
        District: apiData.district || "",
        Consultant: apiData.consultant || "",
        OrderDate: apiData?.orderDate?.split("T")[0] || "",
        Note: apiData.note || "",
        OrderType: apiData.orderType || "",
        WorkDescription: apiData.workDescription || "",
        StationNumber: apiData.stationNumber || "",
        DurationOfImplementation: apiData.durationOfImplementation || "",
        QualificationClassification: apiData.qualificationClassification || "",
        Situation: apiData.situation || "",
        SafetyViolationsExist: apiData.safetyViolationsExist,
        EstimatedValue: apiData.estimatedValue,
        ActualValue: apiData.actualValue,
        ExtractNumber: apiData.extractNumber,
      });
      setFileData({
        ModelPhotos: apiData.modelPhotos || [],
        SitePhotos: apiData.sitePhotos || [],
        SafetyWastePhotos: apiData.safetyWastePhotos || [],
      });
    }
  }, [apiData]);

  const handleChange = (event) => {
    const { name, value } = event.target;

    setFormData((prev) => ({
      ...prev,
      [name]:
        name === "SafetyViolationsExist"
          ? value === "exists"
            ? true
            : false
          : value,
    }));
  };

  const openFileSelector = (fileType) => {
    fileInputRefs[fileType].current.click();
  };

  const handleFileChange = (event, fileType) => {
    const uploadedFiles = Array.from(event.target.files);
    const newFiles = uploadedFiles.filter((file) => {
      if (file.type === "application/pdf") {
        return true;
      }
      return file.size <= 1 * 1024 * 1024 * 1024;
    });
    const rejectedFiles = uploadedFiles.filter((file) => {
      return (
        file.type !== "application/pdf" && file.size > 1 * 1024 * 1024 * 1024
      );
    });

    if (rejectedFiles.length) {
      setErrorMessage("بعض الملفات كانت كبيرة جدًا ولا يمكن رفعها.");
    } else {
      setErrorMessage("");
    }

    setFileData((prev) => ({
      ...prev,
      [fileType]: [...prev[fileType], ...newFiles],
    }));
  };

  const handleFileDelete = (fileType, fileToDelete) => {
    setFileData((prev) => ({
      ...prev,
      [fileType]: prev[fileType].filter(
        (file) => file.name !== fileToDelete.name
      ),
    }));
  };

  const handleSubmit = async (isArchive) => {
    let fieldErrors = [];
    if (formData.Office === "") fieldErrors.push("المكتب");
    if (formData.WorkOrderType === "") fieldErrors.push("نوع  امر العمل");
    if (formData.DurationOfImplementation === "")
      fieldErrors.push("مدة التنفيذ");

    if (formData.Situation === "") fieldErrors.push("الحالة");
    if (formData.ReceiveDateTime === "")
      fieldErrors.push(" تاريخ استلام امر العمل");
    // if ( formData.ExtractNumber === "") fieldErrors.push("رقم  المستخلص");
    // if (   formData.ActualValue === "") fieldErrors.push("القيمة الفعلية");
    // if (formData.StationNumber === "") fieldErrors.push(" رقم المحطه");
    // if (formData.District === "") fieldErrors.push("  الحي");
    // if (formData.WorkDescription === "") fieldErrors.push(" وصف المشروع");
    if (formData.OrderDate === "") fieldErrors.push("تاريخ التنفيذ");
    if (formData.FaultNumber === "") fieldErrors.push("رقم امر العمل  ");
    if (formData.Contractor === "") fieldErrors.push(" المقاول");
    if (formData.WorkDescription === "") fieldErrors.push(" وصف المشروع");
    if (formData.EstimatedValue === "") fieldErrors.push(" القيمه التقديريه ");
    if (formData.SafetyViolationsExist === "")
      fieldErrors.push("هل اخطاء السلامه موجوده ");
    // if (fileData.ModelPhotos.length === 0) fieldErrors.push("المتسندات ");

    if (fieldErrors.length > 0) {
      Swal.fire({
        icon: "error",
        title: "خطأ",
        html: `يرجى ملء الحقول التالية: <br> ${fieldErrors.join(", ")}`,
      });
      return;
    }
    fieldErrors = [];
    const submissionData = new FormData();
    Object.entries(formData).forEach(([key, value]) => {
      submissionData.append(key, value);
    });

    submissionData.append("isArchive", isArchive);

    Object.entries(fileData).forEach(([key, files]) => {
      files.forEach((file) => {
        submissionData.append(key, file);
      });
    });

    setLoading(true);
    setUploadProgress(0);

    try {
      const url = apiData ? updateUrl : createUrl;
      const method = apiData ? "PUT" : "POST";

      await axios({
        method,
        url,
        data: submissionData,
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          setUploadProgress(percentCompleted);
        },
      });
      if (apiData) {
        setSuccessMessage("تم تحديث الطلب بنجاح.");
      } else {
        setSuccessMessage("تم إرسال الطلب بنجاح.");
      }
      setShowModal({ success: true, error: false });
    } catch (error) {
      console.log(error);
      console.log(error);

      let errorMessage =
        error.response?.data?.message || "يرجي  التاكد من البيانات";

      if (error?.response?.data?.errors) {
        const errors = error.response.data.errors;
        const errorMessages = Object.values(errors).flat().join(", ");
        errorMessage = errorMessages || errorMessage;
      } else if (error?.response?.data?.data) {
        errorMessage = error.response.data.data;
      }

      setErrorMessage(errorMessage);

      setShowModal({ success: false, error: true });
    } finally {
      setLoading(false);
    }
  };

  const refreshPage = () => {
    window.location.reload();
  };

  const handleApiFileDelete = async (fileType, fileId) => {
    const endpoint = `${Url}${endpointMap.RehabilitationWorks[fileType]}?photoId=${fileId}`;
    try {
      const response = await axios.delete(endpoint, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.status === 200) {
        setFileData((prev) => ({
          ...prev,
          [fileType]: prev[fileType].filter((item) => item.id !== fileId),
          // Use the token from the user's profile
        }));
        Swal.fire({
          position: "center",

          icon: "success",
          // Update the state with the new list of files
          title: "تم حذف الصوره بنجاح.",
          showConfirmButton: false,
          timer: 1500,
        });

        // Show a success message to the user
      }
    } catch (error) {
      Swal.fire({
        position: "center",
        icon: "error",
        title: "فشل حذف الصورة",
        text: "يرجى المحاولة مرة أخرى.",
        showConfirmButton: false,
        timer: 1500,
        // Show an error message to the user if there was a problem
      });
    }
  };
  return (
    <div className="form-container" dir="rtl">
      <div className="container">
        <div className="FormData">
          <div sx={{ textAlign: "center", marginBottom: "30px" }}>
            <h3>بيانات الطلب</h3>
            <p>Get a Quote Immediately Upon Form Submission</p>
          </div>

          <div className="input-group">
            {/* المكتب */}
            <OfficeSelect
              selectedOffice={formData.Office}
              onOfficeChange={handleChange}
            />{" "}
            {/* نوع امر العمل */}
            <SelectWorkOrderType
              value={formData.WorkOrderType}
              handleChange={handleChange}
            />
          </div>

          <div className="input-group">
            {/* رقم امر العمل */}
            <div className="groub_fe">
              <label>رقم امر العمل</label>
              <input
                type="text"
                name="FaultNumber"
                placeholder="رقم امر العمل"
                value={formData.FaultNumber || ""}
                onChange={handleChange}
                required
              />
            </div>
            {/* الحي */}
            <SelectDistrict
              officeName={formData.Office}
              value={formData.District}
              onChange={handleChange}
            />{" "}
          </div>

          <div className="input-group">
            {/* اسم المقاول */}
            <div className="groub_fe">
              <label>اسم المقاول</label>
              <input
                type="text"
                name="Contractor"
                placeholder="اسم المقاول"
                value={formData.Contractor || ""}
                onChange={handleChange}
                required
              />
            </div>
            {/* رقم المحطة */}
            {/* <div className="groub_fe">
              <label>رقم المحطة</label>
              <input
                type="text"
                name="StationNumber"
                placeholder="رقم المحطة"
                value={formData.StationNumber || ""}
                onChange={handleChange}
                required
              />
            </div> */}
          </div>

          <div className="input-group">
            {/* وصف المشروع */}
            <WorkDescriptionInput
              onChange={handleChange}
              value={formData.WorkDescription}
            />
            {/* تاريخ استلام امر الطلب */}
            <div className="groub_fe">
              <label>تاريخ استلام امر الطلب</label>
              <input
                type="date"
                name="ReceiveDateTime"
                placeholder="تاريخ استلام امر الطلب"
                value={formData.ReceiveDateTime || ""}
                onChange={handleChange}
                required
              />
            </div>
          </div>

          <div className="input-group">
            {/* مدة التنفيذ */}
            <div className="groub_fe">
              <label>مدة التنفيذ</label>
              <input
                type="text"
                name="DurationOfImplementation"
                placeholder="مدة التنفيذ"
                value={formData.DurationOfImplementation || ""}
                onChange={handleChange}
                required
              />
            </div>
            {/* الاستشاري */}
            <SelectConsultant
              value={formData.Consultant}
              onChange={handleChange}
            />
          </div>

          <div className="input-group">
            {/* تاريخ التنفيذ */}
            <div className="groub_fe">
              <label>تاريخ التنفيذ</label>
              <input
                type="date"
                name="OrderDate"
                placeholder="تاريخ التنفيذ"
                value={formData.OrderDate || ""}
                onChange={handleChange}
                required
              />
            </div>
            {/* القيمة التقديرية */}
            <div className="groub_fe">
              <label>القيمة التقديرية</label>
              <input
                type="text"
                name="EstimatedValue"
                placeholder="القيمة التقديرية"
                value={formData.EstimatedValue || ""}
                onChange={handleChange}
                required
              />
            </div>
          </div>

          <div className="input-group">
            {/* القيمة الفعلية */}
            <div className="groub_fe">
              <label>القيمة الفعلية</label>
              <input
                type="text"
                name="ActualValue"
                placeholder="القيمة الفعلية"
                value={formData.ActualValue || ""}
                onChange={handleChange}
                required
              />
            </div>
            {/* رقم المستخلص */}
            <div className="groub_fe">
              <label>رقم المستخلص</label>
              <input
                type="text"
                name="ExtractNumber"
                placeholder="رقم المستخلص"
                value={formData.ExtractNumber || ""}
                onChange={handleChange}
                required
              />
            </div>
          </div>

          <div className="input-group">
            {/* موقف التنفيذ */}
            <SelectSituation
              value={formData.Situation}
              onChange={handleChange}
            />

            <div className="groub_fe">
              <label htmlFor=""> تصنيف التاهيل</label>

              <select
                name="QualificationClassification"
                onChange={handleChange}
                value={formData.QualificationClassification}
                class="border border-gray-300 rounded-lg p-2 w-full"
              >
                <option value="محطات">تأهيل محطات</option>
                <option value="عدادات">تأهيل عدادات</option>
                <option value="كباين">تأهيل كباين</option>
                <option value="هوائي">تأهيل هوائي</option>
                <option value="مسح-وإغلاق">تأهيل مسح وإغلاق المعدات</option>
              </select>
            </div>
          </div>

          {renderUploadSection(
            "المستندات ",
            "ModelPhotos",
            "تصوير مستندات مع الختم",
            handleFileChange,
            fileInputRefs,
            openFileSelector,
            fileData,
            handleApiFileDelete,
            handleFileDelete
          )}
          {renderUploadSection(
            "صور الموقع",
            "SitePhotos",
            "صور الموقع",
            handleFileChange,
            fileInputRefs,
            openFileSelector,
            fileData,
            handleApiFileDelete,
            handleFileDelete
          )}

          <RadioGroup
            label="مخالفات السلامة"
            options={[
              { label: "يوجد", value: "exists" },
              { label: "لا يوجد", value: "not-exists" },
            ]}
            name="SafetyViolationsExist"
            selectedValue={formData.SafetyViolationsExist}
            onChange={handleChange}
          />

          {formData.SafetyViolationsExist &&
            renderUploadSection(
              "صور مخالفات السلامة",
              "SafetyWastePhotos",
              "صور المخالفات",
              handleFileChange,
              fileInputRefs,
              openFileSelector,
              fileData,
              handleApiFileDelete,
              handleFileDelete
            )}

          <div className="input-group">
            <textarea
              name="Note"
              placeholder="الملاحظات"
              value={formData.Note}
              onChange={handleChange}
            />
          </div>

          <RequestBtns
            loading={loading}
            Situation={formData.Situation}
            handleSubmit={handleSubmit}
          />
        </div>
      </div>
      <LoadingModal
        show={loading}
        onHide={() => setLoading(false)}
        uploadProgress={uploadProgress}
      />
      <SuccessModal
        show={showModal.success}
        onHide={refreshPage}
        message={successMessage}
      />
      <ErrorModal
        show={showModal.error}
        onHide={() => setShowModal({ error: false, success: false })}
        message={errorMessage}
      />
    </div>
  );
};

export default Form;
