import { React } from "react";
import Sidebar from "../../Component/Sidebar/Sidebar";
import Header from "../../Component/Header/Header";
import AreasContent from "../../Component/Areas/AreasContent";

function Areas() {
  return (
    <div className="apDiv">
      <Sidebar />
      <div className="body_container" dir="rtl">
        <div className="Header">
          <Header />
        </div>

        <AreasContent />
      </div>
    </div>
  );
}

export default Areas;
