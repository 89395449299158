import React from "react";
import Swal from "sweetalert2";
import { FaCalendarPlus } from "react-icons/fa";
import { postData } from "../../function/FunctionApi";

function VacationRequest() {
  const requestVacation = () => {
    Swal.fire({
      title: "طلب إجازة",
      html: `
        <div class="flex flex-col gap-3 text-right">
          <div class="flex flex-col">
            <label for="vacationFrom" class="font-semibold text-gray-700">تاريخ البدء:</label>
            <input id="vacationFrom" type="date" class="swal2-input p-2 border rounded-lg focus:ring-2 focus:ring-blue-500">
          </div>

          <div class="flex flex-col">
            <label for="vacationTo" class="font-semibold text-gray-700">تاريخ الانتهاء:</label>
            <input id="vacationTo" type="date" class="swal2-input p-2 border rounded-lg focus:ring-2 focus:ring-blue-500">
          </div>

          <div class="flex flex-col">
            <label for="vacationDays" class="font-semibold text-gray-700">عدد الأيام:</label>
            <input id="vacationDays" type="number" class="swal2-input p-2 border rounded-lg focus:ring-2 focus:ring-blue-500" placeholder="عدد الأيام">
          </div>

          <div class="flex flex-col">
            <label for="vacationReason" class="font-semibold text-gray-700">سبب الإجازة:</label>
            <input id="vacationReason" type="text" class="swal2-input p-2 border rounded-lg focus:ring-2 focus:ring-blue-500" placeholder="سبب الإجازة">
          </div>
        </div>
      `,
      showCancelButton: true,
      confirmButtonText: "إرسال الطلب",
      cancelButtonText: "إلغاء",
      preConfirm: () => {
        const from = document.getElementById("vacationFrom").value;
        const to = document.getElementById("vacationTo").value;
        const days = document.getElementById("vacationDays").value;
        const reason = document.getElementById("vacationReason").value;

        if (!from || !to || !days || !reason) {
          Swal.showValidationMessage("يرجى إدخال جميع الحقول");
        }

        return { from, to, days, reason };
      },
    }).then(async (result) => {
      if (result.isConfirmed) {
        await postData(
          "leaverequests/request",
          {
            from: new Date(result.value.from).toISOString(),
            to: new Date(result.value.to).toISOString(),
            days: result.value.days,
            reason: result.value.reason,
          }
        );

        Swal.fire(
          "تم الإرسال!",
          `تم إرسال طلب إجازة من ${result.value.from} إلى ${result.value.to} لمدة ${result.value.days} يوم بسبب "${result.value.reason}"`,
          "success"
        );
      }
    });
  };

  return (
    <button
      onClick={requestVacation}
      className="mt-6 w-full bg-mainColor hover:bg-mainColor/90 text-white py-3 rounded-lg flex items-center justify-center gap-2 text-lg transition"
    >
      <FaCalendarPlus /> طلب إجازة
    </button>
  );
}

export default VacationRequest;
