import * as XLSX from "xlsx";

const useExportToExcel = (filteredRequests, selectedRequests) => {

  const exportToExcel = () => {
    const selectedData = filteredRequests
      .filter((_, index) => selectedRequests.includes(index))
      .map((request) => ({
        "نوع المشروع": request.type,
        "اسم الفرع": request.branchName,
        الاستشاري: request.consultant,
        المقاول: request.contractor,
        الحي: request.district,
        "رقم المحطه": request.stationNumber,
        "تاريخ الطلب": new Date(request.orderDate).toLocaleDateString("ar-EG"),
        "رقم الطلب": request.orderNumber || request.faultNumber,
        المهندس: request.userName,
        "القيمه التقديريه": request.estimatedValue,
        "القيمه الفعليه": request.actualValue,
        "رقم المستخلص": request.estimatedValue,

        أرشيف: request.isArchive ? "نعم" : "لا",
        "تنفيذ الشبكة": request.isNetworkImplemented ? "نعم" : "لا",
        "عدد صور الموقع": request.sitePhotos.length,
        "عدد صور السلامة": request.safetyWastePhotos.length,
        "عدد صور النماذج": request.modelPhotos.length,
        ملاحظات: request.note || "لا توجد ملاحظات",
      }));

    const worksheet = XLSX.utils.json_to_sheet(selectedData);

    const headerStyle = {
      font: { bold: true, color: { rgb: "#222" } },
      fill: { fgColor: { rgb: "4F81BD" } },
      alignment: { vertical: "center", horizontal: "center" },
    };
    const headerRange = XLSX.utils.decode_range(worksheet["!ref"]);
    for (let C = headerRange.s.c; C <= headerRange.e.c; C++) {
      const cellAddress = XLSX.utils.encode_cell({ r: 0, c: C });
      if (!worksheet[cellAddress]) continue;
      worksheet[cellAddress].s = headerStyle;
    }

    const yesStyle = {
      fill: { fgColor: { rgb: "C6EFCE" } },
      font: { color: { rgb: "006100" } },
      alignment: { horizontal: "center" },
    };
    const noStyle = {
      fill: { fgColor: { rgb: "FFC7CE" } },
      font: { color: { rgb: "9C0006" } },
      alignment: { horizontal: "center" },
    };

    for (let R = 1; R <= headerRange.e.r; R++) {
      const isArchiveCell = worksheet[XLSX.utils.encode_cell({ r: R, c: 11 })]; // "أرشيف" column
      const networkImplementedCell =
        worksheet[XLSX.utils.encode_cell({ r: R, c: 12 })]; // "تنفيذ الشبكة" column
      const rowStyle = {
        fill: { fgColor: { rgb: R % 2 === 0 ? "F2F2F2" : "FFFFFF" } },
        alignment: { horizontal: "center" },
      };

      for (let C = 0; C <= headerRange.e.c; C++) {
        const cellAddress = XLSX.utils.encode_cell({ r: R, c: C });
        if (worksheet[cellAddress]) worksheet[cellAddress].s = rowStyle;
      }

      if (isArchiveCell && isArchiveCell.v === "نعم") {
        isArchiveCell.s = yesStyle;
      } else if (isArchiveCell) {
        isArchiveCell.s = noStyle;
      }

      if (networkImplementedCell && networkImplementedCell.v === "نعم") {
        networkImplementedCell.s = yesStyle;
      } else if (networkImplementedCell) {
        networkImplementedCell.s = noStyle;
      }
    }

    worksheet["!cols"] = [
      { wch: 15 },
      { wch: 10 },
      { wch: 15 },
      { wch: 15 },
      { wch: 20 },
      { wch: 20 },
      { wch: 15 },
      { wch: 15 },
      { wch: 15 },
      { wch: 15 },
      { wch: 15 },
      { wch: 10 },
      { wch: 15 },
      { wch: 18 },
      { wch: 20 },
      { wch: 15 },
      { wch: 30 },
    ];

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "طلبات");

    XLSX.writeFile(workbook, "طلبات_محددة.xlsx");
  };

  return { exportToExcel };
};

export default useExportToExcel;
