import React from "react";
import "./Card.css";
import img1 from "../../../Image/eng1.jpeg";
import img2 from "../../../Image/m2.jpeg";
import img3 from "../../../Image/tn3.jpeg";

const Cards = ({ ordersCountByBranch = [], statisticsOrders = {} }) => {
  console.log(ordersCountByBranch, statisticsOrders);

  const {
    completedOrdersCount = 0,
    nonCompletedOrdersCount = 0,
    completedOrderForRehabilitationWorks = 0,
    nonCompletedOrderForRehabilitationWorks = 0,
    completedOrderForConstruction = 0,
    nonCompletedOrderForConstruction = 0,
    completedOrderForMaintenance = 0,
    nonCompletedOrderForMaintance = 0,
    completedOrderForEmergency = 0,
    nonCompletedOrderForEmergency = 0,
    completedOrderForPrivate = 0,
    nonCompletedOrderForPrivate = 0,
    supervisorCount = 0,
  } = statisticsOrders;

  const isAdmin = localStorage.getItem("userType") === "admin";

  const cardsData = [
    ...ordersCountByBranch.map((branch, index) => ({
      id: index + 1,
      img: img1,
      number: branch.engineersCount,
      title: `مهندسين فرع ${branch.branchName}`,
    })),
    {
      id: ordersCountByBranch.length + 1,
      img: img2,
      number: completedOrdersCount,
      title: "الطلبات المكتملة",
    },
    {
      id: ordersCountByBranch.length + 2,
      img: img3,
      number: nonCompletedOrdersCount,
      title: "الطلبات المؤرشفة",
    },
    {
      id: ordersCountByBranch.length + 3,
      img: img3,
      number:
        nonCompletedOrderForRehabilitationWorks +
        completedOrderForRehabilitationWorks,
      title: "طلبات  مشاريع التاهيل",
      details: [
        {
          number: completedOrderForRehabilitationWorks,
          title: "المكتملة",
          img: img3,
        },
        {
          number: nonCompletedOrderForRehabilitationWorks,
          title: "غير المكتملة",
          img: img3,
        },
      ],
    },
    {
      id: ordersCountByBranch.length + 4,
      img: img3,
      number: completedOrderForConstruction + nonCompletedOrderForConstruction,
      title: "طلبات مشاريع البناء",
      details: [
        { number: completedOrderForConstruction, title: "المكتملة", img: img3 },
        {
          number: nonCompletedOrderForConstruction,
          title: "غير المكتملة",
          img: img3,
        },
      ],
    },
    {
      id: ordersCountByBranch.length + 5,
      img: img3,
      number: completedOrderForPrivate + nonCompletedOrderForPrivate,
      title: "طلبات المشاريع الخاصة",
      details: [
        { number: completedOrderForPrivate, title: "المكتملة", img: img3 },
        {
          number: nonCompletedOrderForPrivate,
          title: "غير المكتملة",
          img: img3,
        },
      ],
    },
    {
      id: ordersCountByBranch.length + 6,
      img: img3,
      number: completedOrderForMaintenance + nonCompletedOrderForMaintance,
      title: "طلبات مشاريع الصيانة",
      details: [
        { number: completedOrderForMaintenance, title: "المكتملة", img: img3 },
        {
          number: nonCompletedOrderForMaintance,
          title: "غير المكتملة",
          img: img3,
        },
      ],
    },
    {
      id: ordersCountByBranch.length + 7,
      img: img3,
      number: completedOrderForEmergency + nonCompletedOrderForEmergency,
      title: "طلبات مشاريع الطوارئ",
      details: [
        { number: completedOrderForEmergency, title: "المكتملة", img: img3 },
        {
          number: nonCompletedOrderForEmergency,
          title: "غير المكتملة",
          img: img3,
        },
      ],
    },
    ...(isAdmin
      ? [
          {
            id: ordersCountByBranch.length + 8,
            img: img1,
            number: supervisorCount,
            title: "المشرفين",
          },
        ]
      : []),
  ];

  return (
    <div className="cards" id="BarChart-Cards">
      <div className="cards-container">
        {cardsData.map((card) => (
          <div key={card.id} className="card">
            <div className="flex items-center justify-between">
              <img className="w-12 h-12" src={card.img} alt={card.title} />
              <div>
                <div className="card-number">{card.number}</div>
                <div className="card-title">{card.title}</div>
              </div>
            </div>

            {card.details && (
              <div className="flex items-center justify-between">
                {card.details.map((detail, index) => (
                  <div
                    key={index}
                    className={`detail px-4 py-2 rounded-md ${
                      index % 2 === 0 ? "bg-hoverColor/20" : "bg-mainColor/20"
                    }`}
                  >
                    <span className="text-sm font-medium">
                      {detail.title}: {detail.number}
                    </span>
                  </div>
                ))}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Cards;
