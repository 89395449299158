import { React } from "react";
import Sidebar from "../../Component/Sidebar/Sidebar";
import Header from "../../Component/Header/Header";
import ConsultantsContent from "../../Component/Consultants/ConsultantsContent";

function Consultants() {
  return (
    <div className="apDiv">
      <Sidebar />
      <div className="body_container" dir="rtl">
        <div className="Header">
          <Header />
        </div>

        <ConsultantsContent />
      </div>
    </div>
  );
}

export default Consultants;
