import React from "react";

import EngNotificationContent from "../../Component/EngNotification/EngNotification";
function EngNotification() {
  return (
    <div className="mt-32">
      <EngNotificationContent />
    </div>
  );
}

export default EngNotification;
