import React, { useEffect, useState, useMemo } from "react";
import { fetchDataWithRetries } from "../../function/FunctionApi";
import profilePlaceholder from "../../Image/team-01.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsis, faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { Url } from "../../function/FunctionApi";
import axios from "axios";

function Engineers() {
  const [accounts, setAccounts] = useState([]);
  const [filter, setFilter] = useState("الرياض");
  const [popupVisible, setPopupVisible] = useState(null);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [accountToDelete, setAccountToDelete] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [loadingDelete, setLoadingDelete] = useState(false); 

  useEffect(() => {
    const getData = async () => {
      try {
        setLoading(true);
        await fetchDataWithRetries("Account/accounts", setAccounts);
      } catch (error) {
        setError("Error fetching accounts data. Please try again.");
        console.error("Error fetching accounts data:", error);
      } finally {
        setLoading(false);
      }
    };
    getData();
  }, []);

  // Handle filter change
  const handleFilterChange = (branch) => {
    setFilter(branch);
    setPopupVisible(null);
  };

  // Toggle popup menu
  const togglePopup = (email) => {
    setPopupVisible((prevEmail) => (prevEmail === email ? null : email));
  };

  // Handle delete click
  const handleDeleteClick = (account) => {
    setAccountToDelete(account);
    setDeleteModalVisible(true);
  };

  // Confirm delete account
  const handleConfirmDelete = async () => {
    setLoadingDelete(true); // Set loading state
    try {
      await axios.delete(
        `${Url}Account/remove-account?userName=${accountToDelete.userName}`
      );

      // Update the accounts state to remove the deleted account
      setAccounts((prevAccounts) =>
        prevAccounts.filter(
          (account) => account.userName !== accountToDelete.userName
        )
      );

      // Close the delete modal and reset the account to delete
      setDeleteModalVisible(false);
      setAccountToDelete(null);
      setError(null); // Clear any previous errors
    } catch (error) {
      console.error("Error deleting account:", error);
      setError("Error deleting account. Please try again.");
    } finally {
      setLoadingDelete(false); // Reset loading state
    }
  };

  // Cancel delete operation
  const handleCancelDelete = () => {
    setDeleteModalVisible(false);
    setAccountToDelete(null);
  };

  // Close popup if clicked outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        popupVisible &&
        !event.target.closest(".options-icon") &&
        !event.target.closest(".popup-menu")
      ) {
        setPopupVisible(null);
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [popupVisible]);

  // Memoized filtered accounts
  const filteredAccounts = useMemo(() => {
    return Array.isArray(accounts)
      ? accounts.filter(
          (account) =>
            (account.userType === "مهندس" ||
              account.userType === "eng" ||
              account.userType === "المهندس") &&
            account.branchName === filter
        )
      : [];
  }, [accounts, filter]);

  return (
    <div className="accounts-container">
      <div className="accounts-content">
        <div className="filter-buttons">
          <div>
            <button
              onClick={() => handleFilterChange("الرياض")}
              className={filter === "الرياض" ? "active" : "!w-48 "}
            >
              فرع الرياض
            </button>
            <button
              onClick={() => handleFilterChange("جدة")}
              className={filter === "جدة" ? "active" : "!w-44"}
            >
              فرع جدة
            </button>
          </div>
          <div className=" text-white bg-hoverColor rounded-md py-1 px-3">
            <Link className="text-mainColor" to="/add-account">
              {" "}
              إضافة حساب +
            </Link>
          </div>
        </div>
        {loading ? (
          <p>جاري تحميل البيانات...</p>
        ) : error ? (
          <p className="error-message">{error}</p>
        ) : (
          <div className="accounts-list">
            {filteredAccounts.length > 0 ? (
              filteredAccounts.map((account) => (
                <div key={account.email} className="account-card">
                  <img
                    src={account.userImage || profilePlaceholder}
                    alt={account.userName}
                    className="account-image"
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = profilePlaceholder;
                    }}
                  />
                  <span className="account-name">{account.userName}</span>
                  <span className="account-email">{account.email}</span>
                  <span
                    className={`account-type ${
                      account.userType === "admin" ? "active" : "inactive"
                    }`}
                  >
                    {account.userType === "admin" ? "مشرف" : "مهندس"}
                  </span>
                  <div className="options-icon">
                    <FontAwesomeIcon
                      icon={faEllipsis}
                      onClick={() => togglePopup(account.email)}
                    />
                    {popupVisible === account.email && (
                      <div className="popup-menu">
                        <Link
                          className="edit-button"
                          to={`/add-account/${account.id}`}
                        >
                          <FontAwesomeIcon icon={faEdit} /> تعديل
                        </Link>
                        <div
                          className="delete-button"
                          onClick={() => handleDeleteClick(account)}
                        >
                          <FontAwesomeIcon icon={faTrash} /> مسح
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              ))
            ) : (
              <p>لا توجد بيانات متاحة</p>
            )}
          </div>
        )}
      </div>

      {deleteModalVisible && (
        <div className="delete-modal">
          <div className="delete-modal-content">
            <p>هل أنت متأكد من حذف هذا العنصر؟</p>
            <div className="modal-buttons">
              <button
                onClick={handleConfirmDelete}
                className="confirm-button"
                disabled={loadingDelete}
              >
                {loadingDelete ? "جارٍ الحذف..." : "تأكيد"}
              </button>
              <button onClick={handleCancelDelete} className="cancel-button">
                إلغاء
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Engineers;
