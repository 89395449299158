import React, { useEffect, useReducer, useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import { FaCheck, FaTimes, FaSearch } from "react-icons/fa";
import { getCookie } from "../../Pages/Login/Login";
import { Url } from "../../function/FunctionApi";

const API_URL = Url + "leaverequests";

const initialState = { requests: [] };

function leaveReducer(state, action) {
  switch (action.type) {
    case "SET_REQUESTS":
      return { requests: action.payload };
    case "UPDATE_STATUS":
      return {
        requests: state.requests.map((req) =>
          req.id === action.payload.id
            ? { ...req, status: action.payload.status }
            : req
        ),
      };
    default:
      return state;
  }
}

function LeaveRequests() {
  const [state, dispatch] = useReducer(leaveReducer, initialState);
  const [searchTerm, setSearchTerm] = useState("");

  // Fetch Leave Requests
  useEffect(() => {
    axios
      .get(`${API_URL}/all`)
      .then((response) => {
        if (response.data.statusCode === 200) {
          dispatch({ type: "SET_REQUESTS", payload: response.data.data });
        }
      })
      .catch((error) => console.error("Error fetching leave requests:", error));
  }, []);
  const userCookie = getCookie("user");
  const token = JSON.parse(userCookie).token;
  console.log(token);
  const updateRequestStatus = (id, status) => {
    Swal.fire({
      title: `${status === "Approved" ? "Accept" : "Reject"} Leave?`,
      text: `Are you sure you want to ${status.toLowerCase()} this leave?`,
      icon: status === "Approved" ? "success" : "warning",
      showCancelButton: true,
      confirmButtonText: `Yes, ${status}`,
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .put(
            `${API_URL}/update-status/${id}?status=${status}`,
            null, // No request body needed
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then(() => {
            dispatch({ type: "UPDATE_STATUS", payload: { id, status } });
            Swal.fire(
              `${status}!`,
              `Leave request has been ${status.toLowerCase()}.`,
              "success"
            );
          })
          .catch((error) => {
            console.error("Error updating leave request:", error);
            Swal.fire("Error", "Failed to update leave request.", "error");
          });
      }
    });
  };

  const filteredRequests = state.requests.filter((req) =>
    req.employeeName.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="p-6 max-w-3xl mx-auto  bg-white shadow-lg rounded-lg">
      <h2 className="text-3xl font-bold mb-6 text-center text-gray-800">
        إدارة الإجازات
      </h2>

      {/* Search Bar */}
      <div className="flex items-center gap-2 mb-6 bg-gray-100 p-3 rounded-lg">
        <input
          type="text"
          placeholder="بحث عن طلب إجازة..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="border-none bg-transparent w-full px-3 py-2 focus:outline-none"
        />
        <button
          onClick={() => setSearchTerm("")}
          className="bg-gray-300 hover:bg-gray-400 text-gray-800 px-3 py-2 rounded-lg transition-all"
        >
          <FaSearch />
        </button>
      </div>

      {/* Leave Requests List */}
      {filteredRequests.length === 0 ? (
        <p className="text-center text-gray-500 text-lg">
          لا توجد طلبات إجازة.
        </p>
      ) : (
        <ul className="space-y-4">
          {filteredRequests.map((req) => (
            <li
              key={req.id}
              className="p-4 rounded-xl shadow-md bg-white border border-gray-200 flex justify-between cursor-pointer gap-6 items-center hover:shadow-lg transition-all"
            >
              <div dir="rtl">
                <p
                  dir="rtl"
                  className="font-semibold text-right text-lg  text-gray-900"
                >
                  {req.employeeName}
                </p>
                <p dir="rtl" className="text-gray-600 text-right">
                  سبب الإجازة: {req.reason}
                </p>
                <p dir="rtl" className="text-gray-600 text-right">
                  عدد الأيام: {req.numberOfDays}
                </p>
                <p dir="rtl" className="text-gray-600 text-right">
                  {" "}
                  من: {req.from.split("T")[0]}
                </p>
                <p dir="rtl" className="text-gray-600 text-right">
                  {" "}
                  الي: {req.to.split("T")[0]}
                </p>
                <p
                  dir="rtl"
                  className={`font-bold text-right ${
                    req.status === "Approved"
                      ? "text-green-600"
                      : req.status === "Rejected"
                      ? "text-red-600"
                      : "text-yellow-500"
                  }`}
                >
                  {req.status}
                </p>
              </div>
              <div className="flex items-center gap-3">
                {req.status === "Pending" && (
                  <>
                    <button
                      onClick={() => updateRequestStatus(req.id, "Approved")}
                      className="bg-green-500 text-white px-4 py-2 rounded-lg shadow-md hover:bg-green-600 transition-all flex items-center gap-1"
                    >
                      <FaCheck /> قبول
                    </button>
                    <button
                      onClick={() => updateRequestStatus(req.id, "Rejected")}
                      className="bg-red-500 text-white px-4 py-2 rounded-lg shadow-md hover:bg-red-600 transition-all flex items-center gap-1"
                    >
                      <FaTimes /> رفض
                    </button>
                  </>
                )}
              </div>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}

export default LeaveRequests;
