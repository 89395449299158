const endpointMap = {
  RehabilitationWorks: {
    ModelPhotos: "RehabilitationWorks/model-photo",
    SitePhotos: "RehabilitationWorks/site-photo",
    SafetyWastePhotos: "RehabilitationWorks/safety-photo", 
    TestModels:"RehabilitationWorks/test-model",
  },
  Emergency: {
    ModelPhotos: "Emergency/model-photo",
    SitePhotos: "Emergency/site-photo",
    SafetyWastePhotos: "Emergency/safety-photo", 
    TestModels:"Emergency/test-model",
  },
  Maintenance: {
    ModelPhotos: "Maintenance/model-photo",
    SitePhotos: "Maintenance/site-photo",
    SafetyWastePhotos: "Maintenance/safety-photo",
    TestModels:"Maintenance/test-model",
  },
  Construction: {
    ModelPhotos: "Construction/model-photo",
    SitePhotos: "Construction/site-photo",
    SafetyWastePhotos: "Construction/safety-photo",
    TestModels:"Construction/test-model",
  },
};

export default endpointMap;
