import React from "react";
import Header from "../../Component/Header/Header";
import Sidebar from "../../Component/Sidebar/Sidebar";
import AcceptDeleteOrdersComponent from "../../Component/AcceptDeleteOrdersComponent/AcceptDeleteOrdersComponent";

function AcceptDeteleOrders() {
  return (
    <div className="apDiv SearchRequests">
      <Sidebar />
      <div className="body_container" dir="rtl">
        <div className="Header">
          <Header />
        </div>
        <AcceptDeleteOrdersComponent />
      </div>
    </div>
  );
}

export default AcceptDeteleOrders;
