import React from "react";
import Sidebar from "../../Component/Sidebar/Sidebar";
import Header from "../../Component/Header/Header";
import LeaveRequests from "../../Component/Vacations/Vacations";

function Vacations() {
  return (
    <div className="apDiv">
      <Sidebar />
      <div className="body_container" dir="rtl">
        <div className="Header">
          <Header />
        </div>
        <LeaveRequests />
      </div>
    </div>
  );
}

export default Vacations;
