import axios from "axios";
import { getCookie } from "../Pages/Login/Login";

const userCookie = getCookie("user");

const token = userCookie ? JSON.parse(userCookie).token : "";
console.log("tokkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkken");
console.log(token);
const axiosInstance = axios.create({
  baseURL: "https://api.asf-consulting.com",
  headers: {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  },
});

export default axiosInstance;
