import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Url } from "../../function/FunctionApi";
import {
  faEye,
  faTrashCan,
  faThLarge,
  faBars,
  faEdit,
} from "@fortawesome/free-solid-svg-icons";
import img from "../../Image/Rectangle1.png";
import types from "../../util/ProjectsType";
import Swal from "sweetalert2";
import axios from "axios";

const CompletedUi = ({
  searchTerm,
  setSearchTerm,
  filteredProjects,
  setCompleted,
  noDataImage,
  office,
  setOffice,
  handleDelete,
}) => {
  const handleUpdateExcavationLength = async (id) => {
    const { value: formValues } = await Swal.fire({
      title: "تحديث الحفريه والكيبل المنفذ يوميا",
      html: `
        <input id="excavation-input" class="swal2-input" placeholder="أدخل الحفريه المنفذه يوميا">
        <input id="cable-input" class="swal2-input" placeholder="أدخل طول الكيبل المنفذ يوميا">
      `,
      focusConfirm: false,
      showCancelButton: true,
      confirmButtonText: "تأكيد",
      cancelButtonText: "إلغاء",
      preConfirm: async () => {
        const excavationLength = document.getElementById("excavation-input").value;
        const cableLength = document.getElementById("cable-input").value;
  
        if (!excavationLength || !cableLength) {
          return Swal.showValidationMessage("يجب إدخال جميع القيم!");
        }
  
        Swal.showLoading();
        await axios.put(Url + `Construction/${id}/update-excavation-length`, {
          projectExcavationLengthEnd: excavationLength,
          cableLengthEnd: cableLength,
        });
  
        return { excavationLength, cableLength };
      },
    });
  
    if (formValues) {
      Swal.fire({
        title: "تم التحديث!",
        text: `تم تحديث الحفريه إلى: ${formValues.excavationLength} وطول الكيبل إلى: ${formValues.cableLength}`,
        icon: "success",
        confirmButtonText: "حسنًا",
      });
  
      console.log("Updated Excavation Length:", formValues.excavationLength);
      console.log("Updated Cable Length:", formValues.cableLength);
    }
  };
  
  // const { handShow } = useProjectPopup();
  const [isGridView, setIsGridView] = useState(true);

  return (
    <div className="container mx-auto p-4">
      {/* Top Section */}
      <div className="flex flex-wrap justify-between items-center mb-4 gap-4">
        <input
          type="text"
          placeholder="ابحث عن مشروع..."
          className="border p-2 rounded-md w-1/3 min-w-[200px]"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <h4 className="text-gray-700 font-medium">
          عدد الطلبات ({filteredProjects.length})
        </h4>
        <div className="flex gap-4">
          <button
            onClick={() => setIsGridView(!isGridView)}
            className="p-2 border rounded-md hover:bg-gray-100 transition"
          >
            <FontAwesomeIcon icon={isGridView ? faBars : faThLarge} />
          </button>
          <button
            onClick={() => setCompleted(null)}
            className="p-2 border rounded-md text-indigo-500 hover:bg-indigo-50 transition"
          >
            العوده
          </button>
        </div>
      </div>

      {/* No Data Found */}
      {filteredProjects.length === 0 && (
        <div className="text-center" dir="rtl">
          <img
            src={noDataImage}
            alt="No data available"
            className="mx-auto h-40"
          />
          <p>
            {searchTerm
              ? "لا توجد مشاريع مطابقة لبحثك."
              : "لا يوجد بيانات للعرض"}
          </p>
        </div>
      )}

      <div
        className={
          isGridView
            ? "grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mt-6"
            : "flex flex-col space-y-6 mt-6"
        }
      >
        {filteredProjects.map((project, index) => (
          <div
            key={index}
            className={`bg-white shadow-lg rounded-xl overflow-hidden relative ${
              isGridView ? "" : "flex items-center "
            }`}
          >
            <div className="relative"></div>

            <div className={`p-4 flex-1 ${!isGridView ? " gap-4" : ""}`}>
              <h3 className="text-lg font-bold text-gray-900 mb-3">
                {project.faultType || "مشروع"}
              </h3>
              {project.type === "الإنشاءات" && (
                <button
                  onClick={() => handleUpdateExcavationLength(project.id)}
                  className="absolute top-2 right-0  text-sm bg-green-500 text-white p-2 rounded-full"
                >
                  {" "}
                  <FontAwesomeIcon
                    icon={faEdit}
                    size="lg"
                    className="text-blue-500"
                  />
                </button>
              )}
              <div
                className={`${
                  !isGridView ? "flex flex-wrap gap-2" : "flex flex-col gap-2"
                } text-sm text-gray-700`}
              >
                <p className={`${!isGridView ? "flex flex-col" : "flex"}`}>
                  <span className="font-medium">القسم:</span>{" "}
                  {project.type || "غير محدد"}
                </p>
                <p className={`${!isGridView ? "flex flex-col" : "flex"}`}>
                  <span className="font-medium">رقم امر العمل:</span>{" "}
                  {project.faultNumber || project.orderNumber}
                </p>
                <p className={`${!isGridView ? "flex flex-col" : "flex"}`}>
                  <span className="font-medium">نوع امر العمل:</span>{" "}
                  {project.workOrderType || "غير متاح"}
                </p>

                <p className={`${!isGridView ? "flex flex-col" : "flex"}`}>
                  <span className="font-medium">الفرع:</span>{" "}
                  {project.branchName || "غير متاح"}
                </p>
                <p className={`${!isGridView ? "flex flex-col" : "flex"}`}>
                  <span className="font-medium">المكتب:</span>{" "}
                  {project.office || "غير متاح"}
                </p>
                <p className={`${!isGridView ? "flex flex-col" : "flex"}`}>
                  <span className="font-medium">موقف المشروع:</span>{" "}
                  {/* {SituationOptions.get(project.situation)?.label || "غير متاح"} */}
                </p>
                <p className={`${!isGridView ? "flex flex-col" : "flex"}`}>
                  <span className="font-medium">الحالة:</span>{" "}
                  {project.isArchived ? "تحت التنفيذ" : "تم التنفيذ"}
                </p>
                <p className={`${!isGridView ? "flex flex-col" : "flex"}`}>
                  <span className="font-medium">تاريخ استلام امر العمل:</span>{" "}
                  {new Date(project.receiveDateTime).toLocaleDateString()}
                </p>
                <p className={`${!isGridView ? "flex flex-col" : "flex"}`}>
                  <span className="font-medium">الحي:</span>{" "}
                  {project.district || "غير متاح"}
                </p>

                {project.type === "الإنشاءات" && (
                  <>
                    <p className={`${!isGridView ? "flex flex-col" : "flex"}`}>
                      <span className="font-medium">طول الحفريه للمشروع:</span>{" "}
                      {project.projectExcavationLength || "غير متاح"}
                    </p>

                    <p className={`${!isGridView ? "flex flex-col" : "flex"}`}>
                      <span className="font-medium">طول الحفريه المنفذه:</span>{" "}
                      {project.excavationLength || "غير متاح"}
                    </p>
                    <p className={`${!isGridView ? "flex flex-col" : "flex"}`}>
                      <span className="font-medium"> نسبه الانجاز</span>{" "}
                      {project.completionStatusReport || "غير متاح"}
                    </p>
                  </>
                )}
                <a
                  className="col-span-2 text-center text-blue-600 font-medium hover:underline"
                  href={project.coordinates || "#"}
                >
                  📍 الاحداثيات
                </a>
              </div>

              <div
                className={`flex items-end  justify-end  gap-4  ${
                  isGridView && "mt-5"
                }  justify-center`}
              >
                <Link
                  to={
                    project.type === types.maintenance
                      ? `/maintain-projects/${project.id}`
                      : project.type === types.emergency
                      ? `/emergency-projects/${project.id}`
                      : project.type === types.rehabilitation_work
                      ? `/rehabilitation-works/${project.id}`
                      : `/construction-projects/${project.id}`
                  }
                  className="bg-blue-600 text-white px-4 py-2 rounded-lg hover:bg-blue-700 transition"
                >
                  تعديل الطلب
                </Link>
                <button
                  className="bg-red-500 text-white px-4 py-2 rounded-lg hover:bg-red-600 transition"
                  onClick={() => handleDelete(project.id, project.type)}
                >
                  <FontAwesomeIcon icon={faTrashCan} />
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CompletedUi;
