import axios from "axios";
import { useEffect, useState } from "react";
import { Url } from "../../function/FunctionApi";

const Filter = ({ name, value, onChange }) => {
  const [options, setOptions] = useState([]);
  const [office, setOffice] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [consultants, setConsultants] = useState([]);
  const [taskTypes, setTaskTypes] = useState([]);
  const [contractors, setContractor] = useState([]);
  const [jobDiscription, setJobDiscription] = useState([]);

  const fetchBranches = async () => {
    try {
      const branchesResponse = await axios.get(`${Url}Branch`);
      const consultantsResponse = await axios.get(`${Url}Consultants`);
      const taskTypesResponse = await axios.get(`${Url}WorkOrderType`);
      const contractorsResponse = await axios.get(`${Url}Contractor`);
      const officesResponse = await axios.get(`${Url}Office`);
      const jobDiscription = await axios.get(`${Url}JobDescription`);
      setJobDiscription(jobDiscription.data.data);
      setOffice(officesResponse.data.data);
      setOptions(branchesResponse.data.data);
      setConsultants(consultantsResponse.data.data);
      setTaskTypes(taskTypesResponse.data.data);
      setContractor(contractorsResponse.data.data);
    } catch (err) {
      console.log(err);
      setOptions([]);
    }
  };

  const fetchDistricts = async () => {
    try {
      const response = await axios.get(`${Url}Neighborhood`);
      setDistricts(response.data.data);
    } catch (err) {
      console.log(err);
      setDistricts([]);
    }
  };

  useEffect(() => {
    if (name === "district") {
      fetchDistricts();
    } else {
      fetchBranches();
    }
  }, [name]);

  const inputTypes = {
    requestNumber: { type: "number", placeholder: "رقم الطلب" },
    contractor: { type: "text", placeholder: "المقاول" },
    projectType: { type: "text", placeholder: "نوع المشروع" },
    startDate: { type: "date", placeholder: "اختر تاريخ البدء" },
    endDate: { type: "date", placeholder: "اختر تاريخ الانتهاء" },
    consultant: { type: "text", placeholder: "الاستشاري" },
    stationNumber: { type: "text", placeholder: "رقم المحطة" },
    district: { type: "text", placeholder: "المنطقة" },
    situation: { type: "text", placeholder: " حالة الطلب" },
    orderType: { type: "text", placeholder: "امر العمل" },
    faultNumber: { type: "text", placeholder: "رقم امر العمل" },
    situation1: { type: "text", placeholder: " موقف التنفيذ" },
    office: { type: "text", placeholder: " المكتب" },
    actualValue: { type: "text", placeholder: "القيمه الفعليه" },
    extractValue: { type: "text", placeholder: "رقم المستخلص" },
    estimatedValue: { type: "text", placeholder: "القيمه التقديريه" },
    orderDate: { type: "date", placeholder: "تاريخ التنفيذ" },
    receiveDate: { type: "date", placeholder: " تاريخ الاستلام" },
    jobDescription: { type: "date", placeholder: "الوصف" },
  };

  const getInputProps = (name) => {
    return inputTypes[name] || { type: "text", placeholder: "أدخل قيمة" };
  };

  const { type, placeholder } = getInputProps(name);

  const renderInput = () => {
    switch (name) {
      case "startDate":
        return (
          <div className="filterDate">
            <label>
              <span>
                {name === "startDate" ? "تاريخ البدء:" : "تاريخ الانتهاء:"}
              </span>
              <input
                type={type}
                name={name}
                value={value}
                onChange={onChange}
                placeholder={placeholder}
              />
            </label>
          </div>
        );
      case "district":
        return (
          <select
            name={name}
            value={value}
            onChange={onChange}
            placeholder={placeholder}
          >
            <option value="">اختر الحي</option>
            {districts.map((district) => (
              <option key={district.name} value={district.name}>
                {district.name}
              </option>
            ))}
          </select>
        );

      case "projectType":
        return (
          <select name={name} value={value} onChange={onChange}>
            <option value="" disabled>
              اختر نوع المشروع
            </option>
            <option value="المشاريع">المشاريع</option>
            <option value="الصيانه">الصيانه</option>
            <option value="العمليات">العمليات</option>
            <option value="المشاريع الخاصه">المشاريع الخاصه</option>
          </select>
        );
      case "situation":
        return (
          <select name={name} value={value} onChange={onChange}>
            <option value="">اختر حالة الطلب </option>
            <option value="pending">جاري</option>
            <option value="finish">منتهي</option>
            <option value="notFinished">لم يتم التنفيذ</option>
          </select>
        );
      // case "situation1":
      //   return (
      //     <select name={name} value={value} onChange={onChange}>
      //       <option value="">اختر موقف التنفيذ </option>

      //       <option value="reciveOrder">تم تسليم المقاول</option>
      //       <option value="excuteOrder">تم الاستلام من المقاول</option>
      //       <option value="actualOrder">تم إصدار شهادة إنجاز</option>
      //       <option value="etractValue">منتهي مستخلص</option>
      //     </select>
      //   );

      case "stationNumber":
      case "faultNumber":
      case "extractValue":
      case "estimatedValue":
      case "orderDate":
      case "receiveDate":
      case "actualValue":
        return (
          <input 
            type={type}
            name={name}
            value={value}
            onChange={onChange}
            placeholder={placeholder}
          />
        );

      // case "jobDescription":
      //   return (
      //     <select name={name} value={value} onChange={onChange}>
      //       <option value="">وصف العمل</option>
      //       {jobDiscription.map((option) => (
      //         <option key={option.name} value={option.name}>
      //           {option.name}
      //         </option>
      //       ))}
      //     </select>
      //   );

      // case "orderType":
      //   return (
      //     <input
      //       type={type}
      //       name={name}
      //       value={value}
      //       onChange={onChange}
      //       placeholder={placeholder}
      //     />
      //   );
      // case "consultant":
      //   return (
      //     <select name={name} value={value} onChange={onChange}>
      //       <option value="">الاستشاري</option>
      //       {consultants.map((consultant) => (
      //         <option key={consultant.name} value={consultant.name}>
      //           {consultant.name}
      //         </option>
      //       ))}
      //     </select>
      //   );
      case "contractor":
        return (
          <select name={name} value={value} onChange={onChange}>
            <option value="">المقاول</option>
            {contractors.map((contractor) => (
              <option key={contractor.name} value={contractor.name}>
                {contractor.name}
              </option>
            ))}
          </select>
        );
      case "office":
        return (
          <select name={name} value={value} onChange={onChange}>
            <option value="">المكتب</option>
            {office.map((option) => (
              <option key={option.name} value={option.name}>
                {option.name}
              </option>
            ))}
          </select>
        );
      case "requestNumber":
        return (
          <select name={name} value={value} onChange={onChange}>
            <option value="">نوع امر العمل</option>
            {taskTypes.map((tasktype) => (
              <option key={tasktype.name} value={tasktype.name}>
                {tasktype.name}
              </option>
            ))}
          </select>
        );

      case "requestStatus":
        return (
          <select name={name} value={value} onChange={onChange}>
            <option value=""> الارشفه</option>
            <option value="true"> مؤرشف</option>
            <option value="false">غير مؤرشف</option>
          </select>
        );

      default:
        return (
          <select name={name} value={value} onChange={onChange}>
            <option value="">الفرع</option>
            {options.map((option) => (
              <option key={option.name} value={option.name}>
                {option.name}
              </option>
            ))}
          </select>
        );
    }
  };

  return <div className="filter">{renderInput()}</div>;
};

export default Filter;
