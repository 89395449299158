import React, { useState, useEffect } from "react";
import { domain, fetchDataWithRetries } from "../../function/FunctionApi";

function EngNotificationContent() {
  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchNotifications = async () => {
      try {
        await fetchDataWithRetries("Notification/all", (response) => {
          if (response?.data) {
            setNotifications(
              response.data.map((notif) => ({
                id: notif.id,
                title: notif.notificationType, // Using type as title placeholder
                message: notif.message,
                date: notif.createdAt, // Mapping createdAt to date
                userName: notif.userName,
                userImage: notif.userImage,
              }))
            );
          }
        });
      } catch (error) {
        setError("Failed to fetch notifications. Please try again later.");
      } finally {
        setLoading(false);
      }
    };

    fetchNotifications();
  }, []);

  console.log(notifications, "Notifications");

  if (loading) return <div className="text-center py-4"> اشعارات المهندس</div>;
  if (error)
    return <div className="text-red-500 text-center py-4">{error}</div>;

  return (
    <div className="container min-h-screen mx-auto px-4 py-8">
      <h1 className="text-2xl font-bold text-center mb-6">اشعارات المهندس</h1>
      {notifications.length > 0 ? (
        <ul className="space-y-4">
          {notifications.map((notification) => (
            <li
              key={notification.id}
              className="bg-white p-6 rounded-lg shadow-md"
            >
              <div className="flex items-center space-x-4">
                {notification.userImage && (
                  <img
                    src={domain + notification.userImage}
                    alt={notification.userName}
                    className="w-12 h-12 rounded-full"
                  />
                )}
                <div>
                  <h3 className="text-lg font-semibold">
                    {notification.title}
                  </h3>
                  <p className="text-gray-600">{notification.message}</p>
                  <small className="text-sm text-gray-500">
                    {new Date(notification.date).toLocaleString()}
                  </small>
                  <p className="text-sm text-gray-700">
                    <strong>{notification.userName}</strong>
                  </p>
                </div>
              </div>
            </li>
          ))}
        </ul>
      ) : (
        <p className="text-center text-gray-600">لا يوجد اشعارات.</p>
      )}
    </div>
  );
}

export default EngNotificationContent;
