import React from 'react';
import './AboutComponent.css';

const About = () => {
    return (
        <div className="about-section">
            <div className='container'>
                <h2 className="about-title">من نحن</h2>
                <div className="about-content">
                    <p className="about-paragraph">
                        هي شركة رائدة في تقديم استشارات هندسية متكاملة، حيث نركز على تقديم حلول تصميم هندسي مبتكرة وشاملة لتلبية احتياجات مشاريعكم. فريقنا من المهندسين المتخصصين يمتلك خبرة واسعة في تقديم استشارات دقيقة ومهنية في مختلف مجالات الهندسة، بما في ذلك الاعمال الكهربيه والتصميم المعماري، الهندسة المدنية، والميكانيكية. نحن ملتزمون بتوفير استشارات ذات جودة عالية لضمان نجاح مشاريعكم وتحقيق رؤيتكم بكفاءة واحترافية.
                    </p>
                    <p className="about-paragraph">
                        هي شركة رائدة في تقديم استشارات هندسية متكاملة، حيث نركز على تقديم حلول تصميم هندسي مبتكرة وشاملة لتلبية احتياجات مشاريعكم. فريقنا من المهندسين المتخصصين يمتلك خبرة واسعة في تقديم استشارات دقيقة ومهنية في مختلف مجالات الهندسة، بما في ذلك الاعمال الكهربيه والتصميم المعماري، الهندسة المدنية، والميكانيكية. نحن ملتزمون بتوفير استشارات ذات جودة عالية لضمان نجاح مشاريعكم وتحقيق رؤيتكم بكفاءة واحترافية.
                    </p>
                </div>
            </div>
        </div>
    );
};

export default About;
