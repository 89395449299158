import React from "react";
import Sidebar from "../../Component/Sidebar/Sidebar";
import Header from "../../Component/Header/Header";
import WorkersComponents from "../../Component/Employess/EmployeComponent";

function Employees() {
  return (
    <div className="apDiv">
      <Sidebar />
      <div className="body_container" dir="rtl">
        <div className="Header">
          <Header />
        </div>
        <WorkersComponents />
      </div>
    </div>
  );
}

export default Employees;
