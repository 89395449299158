import axios from "axios";
import { getCookie } from "../Pages/Login/Login";

export const Url = "https://api.asf-consulting.com/api/";
export const domain = "https://api.asf-consulting.com";



export async function fetchDataWithRetries(URL, setApiData, status) {
  try {
    const userCookie = getCookie("user");
    const token = JSON.parse(userCookie).token;
    const response = await axios.get(`${Url}${URL}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    console.log(response);
    setApiData(response.data);
    status(response.status);
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
  }
}

export async function postData(APiURL, userData, setError) {
  try {
    const userCookie = getCookie("user");
    const token = JSON.parse(userCookie).token;
    const response = await axios.post(`${Url}${APiURL}`, userData, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (error) {
    console.error(error);
    if (error.response && error.response.status === 401) {
      setError("كلمة السر أو البريد الإلكتروني غير صحيح");
    } else if (error.response && error.response.status === 405) {
      setError("الطلب غير مسموح.");
    } else {
      setError("حدث خطأ ما. حاول مرة أخرى لاحقاً.");
    }
    throw error;
  }
}

export async function postDatatoQueryParams(APiURL, params, setError) {
  try {
    const queryParams = new URLSearchParams(params).toString();
    const fullUrl = `${Url}${APiURL}?${queryParams}`;
    const response = await axios.post(fullUrl, null, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response;
  } catch (error) {
    setError(error);
    console.error(error);
    throw error;
  }
}

export async function putDataToQueryParams(APiURL, params) {
  try {
    const queryParams = new URLSearchParams(params).toString();
    const fullUrl = `${Url}${APiURL}?${queryParams}`;
    const response = await axios.put(fullUrl, null, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function deleteFunction(APiURL) {
  try {
    const response = await axios.delete(`${Url}${APiURL}`, {
      headers: {
        "X-Request-With": "XMLHttpRequest",
      },
    });
    return response;
  } catch (error) {
    if (error.response && error.response.status === 404) {
      console.log(`Resource not found: ${APiURL}`);
    } else {
      console.error(`Error deleting resource: ${APiURL}`, error);
    }
  }
}
