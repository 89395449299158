import React from "react";

const ProfileForm = ({ profile, handleChange, handleSubmit }) => {
  console.log("ProfileForm", profile);

  return (
    <form
      onSubmit={handleSubmit}
      className="flex flex-wrap items-center justify-center gap-6 p-6 bg-white shadow-md rounded-lg text-right"
      dir="rtl"
    >
      {[
        { label: "الاسم الكامل", name: "fullName", type: "text" },
        { label: "تاريخ التوظيف", name: "hireDate", type: "date" },
        { label: "المسمى الوظيفي", name: "jobTitle", type: "text" },
        { label: "تاريخ الميلاد", name: "dateOfBirth", type: "date" },
        { label: "التخصص", name: "specialization", type: "text" },
        { label: "سنوات الخبرة", name: "experienceYears", type: "number" },
        { label: "الشهادات", name: "certifications", type: "text" },
        {
          label: "أيام الإجازة السنوية",
          name: "annualLeaveDays",
          type: "number",
        },
        {
          label: "تاريخ انتهاء الإقامة",
          name: "residenceExpiryDate",
          type: "date",
        },
      ].map((field) => (
        <div key={field.name} className="flex w-[350px] flex-col">
          <label className="font-semibold text-gray-700 mb-1">
            {field.label}
          </label>
          <input
            type={field.type}
            name={field.name}
            value={
              typeof profile[field.name] === "string" && profile[field.name].includes("T")
                ? profile[field.name].split("T")[0]
                : profile[field.name] || ""
            }
            
            onChange={handleChange}
            className="p-3 border rounded-lg focus:outline-none focus:ring-2 focus:ring-orange-400 text-right"
          />
        </div>
      ))}

      {/* Bio Section */}
      <div className="w-full flex flex-col">
        <label className="font-semibold text-gray-700 mb-1">
          السيرة الذاتية
        </label>
        <textarea
          name="bio"
          value={profile.bio || ""}
          onChange={handleChange}
          rows="4"
          className="p-3 border rounded-lg resize-none focus:outline-none focus:ring-2 focus:ring-orange-400 text-right"
        ></textarea>
      </div>

      {/* Uploaded Documents Section */}
      {profile.files1 && (
        <div className="w-full flex flex-col">
          <label className="font-semibold text-gray-700 mb-1">
            الملف المرفوع
          </label>
          <a
            href={profile.files1}
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-500 underline"
          >
            عرض الملف
          </a>
        </div>
      )}

      {/* Upload New Documents Section */}
      <div className="w-full flex flex-col">
        <label className="font-semibold text-gray-700 mb-1">
          تحميل المستندات
        </label>
        <input
          type="file"
          name="documents"
          onChange={handleChange}
          className="p-3 border rounded-lg focus:outline-none focus:ring-2 focus:ring-orange-400 text-right"
        />
      </div>

      {/* Submit Button */}
      <button
        type="submit"
        className="w-full py-3 rounded-lg font-semibold mt-4  bg-secondaryColor text-white hover:bg-orange-600 transition-all duration-300"
      >
        تحديث البيانات
      </button>
    </form>
  );
};

export default ProfileForm;
