import React, { useReducer, useState, useEffect } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import { FaUserEdit, FaTrash, FaPlus, FaSearch } from "react-icons/fa";

const API_URL = "http://asff.runasp.net/api/Employees";

// Initial state
const initialState = { employees: [] };

// Reducer function
function employeeReducer(state, action) {
  switch (action.type) {
    case "SET_EMPLOYEES":
      return { employees: action.payload };
    case "ADD_EMPLOYEE":
      return { employees: [...state.employees, action.payload] };
    case "DELETE_EMPLOYEE":
      return { employees: state.employees.filter((emp) => emp.id !== action.payload) };
    case "UPDATE_EMPLOYEE":
      return {
        employees: state.employees.map((emp) =>
          emp.id === action.payload.id ? action.payload : emp
        ),
      };
    default:
      return state;
  }
}

function WorkersComponents() {
  const [state, dispatch] = useReducer(employeeReducer, initialState);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    axios.get(API_URL)
      .then((response) => {
        if (response.data.statusCode === 200) {
          dispatch({ type: "SET_EMPLOYEES", payload: response.data.data });
        }
      })
      .catch((error) => console.error("Error fetching employees:", error));
  }, []);

  // Handle Add / Edit Employee Form
  const handleEmployeeForm = (employee = null) => {
    Swal.fire({
      title: employee ? "Update Employee" : "Add Employee",
      html: `
        <input id="swal-name" class="swal2-input" placeholder="اسم العامل" value="${employee ? employee.name : ""}" />
        <input id="swal-salary" class="swal2-input" placeholder="الراتب" value="${employee ? employee.salary : ""}" />
        <input id="swal-phone" class="swal2-input" placeholder="الرقم" value="${employee ? employee.phone : ""}" />
        <input id="swal-email" class="swal2-input" placeholder="الايمل" value="${employee ? employee.email : ""}" />
      `,
      showCancelButton: true,
      confirmButtonText: employee ? "Update" : "Add",
      preConfirm: () => {
        const name = document.getElementById("swal-name").value;
        const salary = document.getElementById("swal-salary").value;
        const phone = document.getElementById("swal-phone").value;
        const email = document.getElementById("swal-email").value;

        if (!name || !salary || !phone || !email) {
          Swal.showValidationMessage("All fields are required");
          return false;
        }
        return { id: employee ? employee.id : null, name, salary, phone, email };
      },
    }).then((result) => {
      if (result.isConfirmed) {
        if (employee) {
          axios.put(`${API_URL}/${employee.id}`, result.value)
            .then(() => {
              dispatch({ type: "UPDATE_EMPLOYEE", payload: result.value });
              Swal.fire("Updated!", "Employee details updated.", "success");
            });
        } else {
          axios.post(API_URL, result.value)
            .then((response) => {
              dispatch({ type: "ADD_EMPLOYEE", payload: response.data });
              Swal.fire("Added!", "Employee has been added.", "success");
            });
        }
      }
    });
  };

  // Delete Employee
  const deleteEmployee = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios.delete(`${API_URL}/${id}`)
          .then(() => {
            dispatch({ type: "DELETE_EMPLOYEE", payload: id });
            Swal.fire("Deleted!", "Employee has been removed.", "success");
          });
      }
    });
  };

  // Filter Employees
  const filteredEmployees = state.employees.filter((emp) =>
    emp.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="p-6 max-w-3xl mx-auto bg-white shadow-lg rounded-lg">
      <h2 className="text-2xl font-bold mb-4 text-center">اداره العاملين</h2>

      <div className="flex items-center gap-2 mb-4">
        <input dir="rtl" type="text" placeholder="بحث العاملين" value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} className="border p-2 w-full rounded" />
        <button onClick={() => setSearchTerm("")} className="bg-gray-200 p-2 rounded">
          <FaSearch className="text-gray-700" />
        </button>
      </div>

      <div className="text-right mb-4">
        <button dir="rtl" onClick={() => handleEmployeeForm()} className="bg-mainColor text-white px-4 py-2 rounded flex items-center gap-2">
          <FaPlus /> اضافه عامل
        </button>
      </div>

      {filteredEmployees.length === 0 ? (
        <p className="text-center text-gray-500">No employees found.</p>
      ) : (
        <ul className="space-y-3">
          {filteredEmployees.map((emp) => (
            <li key={emp.id} className="border p-3 rounded-lg flex justify-between items-center bg-gray-100">
              <div>
                <p className="font-semibold">{emp.name}</p>
                <p className="text-gray-600">{emp.salary} - {emp.phone} - {emp.email}</p>
              </div>
              <div className="flex items-center gap-2">
                <button onClick={() => handleEmployeeForm(emp)} className="bg-yellow-500 text-white px-3 py-1 rounded flex items-center gap-1">
                  <FaUserEdit /> Edit
                </button>
                <button onClick={() => deleteEmployee(emp.id)} className="bg-red-500 text-white px-3 py-1 rounded flex items-center gap-1">
                  <FaTrash /> Delete
                </button>
              </div>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}

export default WorkersComponents;
