import React from "react";
import { useNavigate } from "react-router-dom";
import types from "../../util/ProjectsType";

function RequestsTable({
  filteredRequests,
  selectedRequests,
  handleCheckboxChange,
}) {
  const navigate = useNavigate();

  const getProjectTypeRoute = (orderType) => {
    switch (orderType) {
      case types.construction:
        return "construction";
      case types.emergency:
        return "emergency";
      case types.rehabilitation_work:
        return "rehabilitationworks";

      case types.maintenance:
        return "maintenance";
      default:
        return "privateproject";
    }
  };

  return (
    <div className="overflow-x-auto h-[50vh] relative">
      <table className="w-full border-collapse border border-gray-300">
        <thead className="bg-mainColor text-white sticky top-0 z-10">
          {" "}
          <tr className="bg-secondaryColor text-white">
            <th className="p-1 text-sm text-center">اختيار</th>
            <th className="p-1 text-sm text-center">الفرع</th>
            <th className="p-1 text-sm text-center">المكتب</th>
            <th className="p-1 text-sm text-center">رقم أمر العمل</th>
            <th className="p-1 text-sm text-center">نوع العمل</th>
            <th className="p-1 text-sm text-center">وصف المشروع</th>
            <th className="p-1 text-sm text-center">القيمة التقديرية</th>
            <th className="p-1 text-sm text-center">تاريخ الاستلام</th>
            <th className="p-1 text-sm text-center">تاريخ التنفيذ</th>
            <th className="p-1 text-sm text-center">القيمة الفعلية</th>
            <th className="p-1 text-sm text-center">رقم المستخلص</th>
            <th className="p-1 text-sm text-center">حالة الطلب</th>
            <th className="p-1 text-sm text-center">موقف التنفيذ</th>
          </tr>
        </thead>
        <tbody>
          {filteredRequests?.map((request, index) => (
            <tr
              key={index}
              className="border border-gray-300 text-center cursor-pointer"
              // onClick={() =>
              //   navigate(
              //     `/project/${getProjectTypeRoute(request.type)}/${request.id}`
              //   )
              // }
            >
              <td className="p-2" onClick={(e) => e.stopPropagation()}>
                <input
                  type="checkbox"
                  checked={selectedRequests.includes(index)}
                  onChange={(e) => {
                    e.stopPropagation();
                    handleCheckboxChange(index);
                  }}
                />
              </td>
              <td className="p-2">{request.branchName || "لا يوجد"}</td>
              <td className="p-2">{request.office || "لا يوجد"}</td>
              <td className="p-2">{request.faultNumber || "لا يوجد"}</td>
              <td className="p-2">{request.workOrderType || "لا يوجد"}</td>
              <td className="p-2">{request.workDescription || "لا يوجد"}</td>
              <td className="p-2">{request.estimatedValue || "لا يوجد"}</td>
              <td className="p-2">
                {request.receiveDateTime?.split("T")[0] || "لا يوجد"}
              </td>
              <td className="p-2">
                {request.orderDate?.split("T")[0] || "لا يوجد"}
              </td>
              <td className="p-2">{request.actualValue || "لا يوجد"}</td>
              <td className="p-2">{request.extractNumber || "لا يوجد"}</td>
              <td className="p-2">
                {request.situation === "finish"
                  ? "تم التنفيذ"
                  : request.situation === "notFinished"
                  ? "تحت التنفيذ"
                  : "جاري"}
              </td>
              <td className="p-2">
                {request.receiveDateTime &&
                !request.orderDate &&
                !request.actualValue &&
                !request.extractNumber
                  ? "تم تسليم المقاول"
                  : request.orderDate &&
                    request.receiveDateTime &&
                    !request.actualValue &&
                    !request.extractNumber
                  ? "تم الاستلام من المقاول"
                  : request.actualValue &&
                    request.orderDate &&
                    request.receiveDateTime &&
                    !request.extractNumber
                  ? "تم إصدار شهادة الإنجاز"
                  : request.extractNumber
                  ? "منتهي مستخلص"
                  : "لا يوجد"}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default RequestsTable;
