// OfficeSelect.js
import React, { useEffect, useState } from "react";
import axios from "axios";
import { Url } from "../../function/FunctionApi";

function OfficeSelect({ selectedOffice, onOfficeChange, global = false }) {
  const branchId = localStorage.getItem("branchId") || 1;
  const [offices, setOffices] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const uri =
    global === true ? `${Url}Office` : `${Url}Office/offices/${branchId}`;
  useEffect(() => {
    const fetchOffices = async () => {
      setLoading(true);
      setError("");

      try {
        const response = await axios.get(uri);

        if (response.data && response.data.statusCode === 200) {
          setOffices(response.data.data);
        } else {
          setError("Error fetching office data.");
        }
      } catch (err) {
        setError("Error fetching office data.");
        console.error("Error fetching offices:", err);
      } finally {
        setLoading(false);
      }
    };

    fetchOffices();
  }, [branchId]);

  return (
    <div className="groub_fe">
      <label>المكتب</label>
      <select
        id="Office"
        name="Office"
        className="selectSituation"
        onChange={onOfficeChange}
        value={selectedOffice}
      >
        <option value="" disabled>
          اختر المكتب
        </option>
        {loading ? (
          <option>جاري التحميل...</option>
        ) : error ? (
          <option>{error}</option>
        ) : (
          offices.map((office) => (
            <option key={office.id} value={office.name}>
              {office.name}
            </option>
          ))
        )}
      </select>
    </div>
  );
}

export default OfficeSelect;
