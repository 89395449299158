import { React } from "react";
import Sidebar from "../../Component/Sidebar/Sidebar";
import Header from "../../Component/Header/Header";
import OfficesContent from "../../Component/Offices/OfficesContent";

function Offices() {
  return (
    <div className="apDiv">
      <Sidebar />
      <div className="body_container" dir="rtl">
        <div className="Header">
          <Header />
        </div>
        <OfficesContent />
      </div>
    </div>
  );
}

export default Offices;
