import React from "react";
import {
  ErrorModal,
  LoadingModal,
  SuccessModal,
} from "../../Component/Common/ModelComponents";
import RequestBtns from "../../Component/RequestBtns/RquestBtns";
import renderUploadSection from "../../Component/RenderFile/RenderFile";
import SelectSituation from "../../Component/SelectSitution/SelectSitudation";
import SelectConsultant from "../../Component/SelectConsultant/SelectConusltant";
import WorkDescriptionInput from "../../Component/WorkDescriptionInput/WorkDescriptionInput";
import SelectDistrict from "../../Component/SelectDistrict/SelectDistrict";
import RequestStatus from "../../Component/RequestStatus/RequestStatus";
import SelectOffice from "../../Component/SelectOffice/SelectOffice";
import RadioGroup from "../../Component/RadioGroup/RadioGroup";
import SelectWorkOrderType from "../../Component/SelectWorkOrderType/SelectWorkOrderType";
import OfficeSelect from "../../Component/SelectOffice/SelectOffice";
import EquipmentTestTypeSelect from "../../Component/EquipmentTestTypeSelect/EquipmentTestTypeSelect";
import NumberOfEquipment from "../../Component/NumberOfEquipment/NumberOfEquipment";

function FomInputs({
  handleChange,
  handleFileChange,
  fileInputRefs,
  openFileSelector,
  fileData,
  handleApiFileDelete,
  handleFileDelete,
  errorMessage,
  setShowModal,
  showModal,
  successMessage,
  refreshPage,
  uploadProgress,
  setLoading,
  loading,
  formData,
  handleSubmit,
}) {
  return (
    <div className="form-container" dir="rtl">
      <div className="container">
        <div className="FormData">
          <div sx={{ textAlign: "center", marginBottom: "30px" }}>
            <h3>بيانات الطلب</h3>
            <p>Get a Quote Immediately Upon Form Submission</p>
          </div>

          <div className="flex flex-wrap flex-col lg:flex-row gap-3">
            <OfficeSelect
              selectedOffice={formData.Office}
              onOfficeChange={handleChange}
            />{" "}
            <div className="groub_fe">
              <label>رقم امر العمل</label>
              <input
                type="text"
                name="FaultNumber"
                placeholder="نوع امر العمل"
                value={formData.FaultNumber || ""}
                onChange={handleChange}
                required
              />
            </div>
          </div>

          <div className="flex flex-wrap flex-col lg:flex-row gap-3">
            <div className="groub_fe">
              <label>رقم الاشعار</label>
              <input
                type="text"
                name="NotificationNumber"
                placeholder="رقم الاشعار"
                value={formData.NotificationNumber || ""}
                onChange={handleChange}
                required
              />
            </div>
            <div className="groub_fe">
              <label>رقم المهمة</label>
              <input
                type="text"
                name="TaskNumber"
                placeholder="رقم المهمه"
                value={formData.TaskNumber || ""}
                onChange={handleChange}
                required
              />
            </div>
            <EquipmentTestTypeSelect
              value={formData.TypeOfStomachTest}
              handleChange={handleChange}
            />
          </div>
          {/* <RequestStatus
            situation={formData.Situation}
            handleChange={handleChange}
            implementationPhase={formData.ImplementationPhase}
          /> */}
          {/* Second Group */}
          <div className="flex flex-wrap flex-col lg:flex-row gap-3">
            <NumberOfEquipment
              value={formData.NumberOfEquipment}
              handleChange={handleChange}
            />
            <SelectWorkOrderType
              value={formData.WorkOrderType}
              handleChange={handleChange}
            />
          </div>

          {/* Third Group */}
          <div className="flex flex-wrap flex-col lg:flex-row gap-3">
            <div className="groub_fe">
              <label>اسم المقاول</label>
              <input
                type="text"
                name="Contractor"
                placeholder="اسم المقاول"
                value={formData.Contractor || ""}
                onChange={handleChange}
                required
              />
            </div>
            <SelectDistrict
              officeName={formData.Office}
              value={formData.District}
              onChange={handleChange}
            />{" "}
            {/* <div className="groub_fe">
              <label>رقم المحطة</label>
              <input
                type="text"
                name="StationNumber"
                placeholder="رقم المحطة"
                value={formData.StationNumber || ""}
                onChange={handleChange}
                required
              />
            </div> */}
          </div>

          {/* Fourth Group */}
          <div className="flex flex-wrap flex-col lg:flex-row gap-3">
            <div className="groub_fe">
              <label>تاريخ استلام امر العمل</label>
              <input
                type="date"
                name="ReceiveDateTime"
                value={formData.ReceiveDateTime || ""}
                onChange={handleChange}
                required
              />
            </div>
            <div className="groub_fe">
              <label>مدة التنفيذ</label>
              <input
                type="text"
                name="DurationOfImplementation"
                placeholder="مدة التنفيذ"
                value={formData.DurationOfImplementation || ""}
                onChange={handleChange}
                required
              />
            </div>
          </div>

          {/* Fifth Group */}
          <div className="flex flex-wrap flex-col lg:flex-row gap-3">
            <WorkDescriptionInput
              onChange={handleChange}
              value={formData.WorkDescription}
            />
            <SelectConsultant
              value={formData.Consultant}
              onChange={handleChange}
            />
          </div>

          <div className="flex flex-wrap flex-col lg:flex-row gap-3">
            <div className="groub_fe">
              <label>رقم المحطه</label>
              <input
                type="text"
                name="StationNumber"
                placeholder="رقم المحطه"
                value={formData.StationNumber || ""}
                onChange={handleChange}
                required
              />
            </div>
            <div className="groub_fe">
              <label>تاريخ التنفيذ</label>
              <input
                type="date"
                name="OrderDate"
                value={formData.OrderDate || ""}
                onChange={handleChange}
                required
              />
            </div>

            <div className="groub_fe">
              <label>القيمة التقديرية</label>
              <input
                type="text"
                name="EstimatedValue"
                placeholder="القيمة التقديرية"
                value={formData.EstimatedValue || ""}
                onChange={handleChange}
                required
              />
            </div>
          </div>

          {/* Seventh Group (Conditional) */}

          <div className="flex flex-wrap flex-col lg:flex-row gap-3">
            <div className="groub_fe">
              <label>القيمة الفعلية</label>
              <input
                type="text"
                name="ActualValue"
                placeholder="القيمة الفعلية"
                value={formData.ActualValue || ""}
                onChange={handleChange}
                required
              />
            </div>
            <div className="groub_fe">
              <label>رقم المستخلص</label>
              <input
                type="text"
                name="ExtractNumber"
                placeholder="رقم المستخلص"
                value={formData.ExtractNumber || ""}
                onChange={handleChange}
                required
              />
            </div>
          </div>

          <div className="flex flex-wrap flex-col lg:flex-row gap-3">
            <SelectSituation
              value={formData.Situation}
              onChange={handleChange}
            />
          </div>

          <div className="input-group">
            <textarea
              name="Note"
              placeholder="الملاحظات"
              value={formData.Note}
              onChange={handleChange}
            />
          </div>
          <RadioGroup
            label="مخالفات السلامة"
            options={[
              { label: "يوجد", value: "exists" },
              { label: "لا يوجد", value: "not-exists" },
            ]}
            name="SafetyViolationsExist"
            selectedValue={formData.SafetyViolationsExist}
            onChange={handleChange}
          />

          {formData.SafetyViolationsExist &&
            renderUploadSection(
              "صور مخالفات السلامة",
              "SafetyWastePhotos",
              "صور المخالفات",
              handleFileChange,
              fileInputRefs,
              openFileSelector,
              fileData,
              handleApiFileDelete,
              handleFileDelete
            )}
          <div className="input-group">
            <textarea
              name="DescriptionViolation"
              placeholder="وصف المخالفه"
              value={formData.DescriptionViolation}
              onChange={handleChange}
            />
          </div>
          {renderUploadSection(
            "المستندات ",
            "ModelPhotos",
            "تصوير مستندات مع الختم",
            handleFileChange,
            fileInputRefs,
            openFileSelector,
            fileData,
            handleApiFileDelete,
            handleFileDelete
          )}
          {renderUploadSection(
            "صور الموقع",
            "SitePhotos",
            "صور الموقع",
            handleFileChange,
            fileInputRefs,
            openFileSelector,
            fileData,
            handleApiFileDelete,
            handleFileDelete
          )}
          {renderUploadSection(
            "مستندات الاختبار",
            "TestModels",
            "مستندات الاختبار",
            handleFileChange,
            fileInputRefs,
            openFileSelector,
            fileData,
            handleApiFileDelete,
            handleFileDelete
          )}

          <RequestBtns
            loading={loading}
            Situation={formData.Situation}
            handleSubmit={handleSubmit}
          />
        </div>
      </div>
      {/* Progress Modal */}
      <LoadingModal
        show={loading}
        onHide={() => setLoading(false)}
        uploadProgress={uploadProgress}
      />
      <SuccessModal
        show={showModal.success}
        onHide={refreshPage}
        message={successMessage}
      />
      <ErrorModal
        show={showModal.error}
        onHide={() => setShowModal({ error: false, success: false })}
        message={errorMessage}
      />
    </div>
  );
}

export default FomInputs;
